.ad {
    margin: 0 0 bsu(2) 0;
}

.ad-full-width {
    width: 100%;
}

.ad-half-width {
    width: 48%;

    &.two-column-first {
        margin-right: 4%;
    }
}

.banner-ad {
    display: none;

    img {
        border: 1px solid $iron;
        margin: 0 auto;
        text-align: center;
    }
}

@include bp(sm) {
    .banner-ad {
        display: block;
    }
}
