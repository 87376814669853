.u-debug * {
    outline: 1px solid gold !important;
}

.u-debug--white * {
    outline: 1px solid white !important;
}

.u-debug--black * {
    outline: 1px solid black !important;
}
