.two-columns {
    margin-bottom: bsu(2);
    width: 100%;

    .column {
        margin-bottom: bsu(2);
    }

    table {
        tr,
        thead {
            border-top: 2px solid $hub;
            margin: 0 0 bsu(1) 0;

            &:first-of-type {
                border: none;
            }

            th,
            td {
                text-align: left;
                overflow: hidden;

                &:before {
                    font-weight: 400;
                }
            }
        }
        
        &.non-responsive {
            tr {
                border: none;
                margin: 0;

                td {
                    padding: bsu(0.75);
                }
            }
        }
    }
}

.two-columns.records {
    table {
        tr,
        thead {
            th,
            td {
                &:before {
                    margin: 0;
                    width: 54%;
                }
            }
        }
    }
}

@include bp(md) {
    .two-columns {
        margin-bottom: bsu(2);
    
        table {
            tr {
                background: $hub;
                border-top: none;

                th {
                    color: $white;
                    padding: bsu(0.5) bsu(0.75) bsu(0.5) bsu(0.75);
                }

                td {
                    padding: bsu(0.75) bsu(0.75) bsu(0.75) bsu(0.75);
                }
            }

            &.checkbox-table {
                tr {
                    th {
                        padding: bsu(0.5) bsu(0.75) bsu(0.5) bsu(0.75);
                    }
                }
            }
        }
    }

    .two-columns.view-report {
        table {
            tr,
            thead {
                td {
                    padding-left: bsu(0.75);
                }
            }
        }
    }
}    
    
//wrappers
@include bp(sm) {
    .two-columns {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        justify-content: flex-start;

        .column {
            margin: 0 2% 0 0;
            width: 49%;

            &:nth-of-type(2n+2) {
                margin: 0;
            }
        }

        &.wide-gutter {
            .column {
                margin: 0 8% 0 0;
                width: 46%;

                &:nth-of-type(2n+2) {
                    margin: 0;
                }
            }
        }

        &.wide-gutter-ipad-landscape-up {
            .column {
                margin: 0;
                width: 100%;
    
                &:nth-of-type(2n+2) {
                    margin: 0;
                }
            }
        }
    }
}

@include bp(md) {
    .two-columns {
        &.wide-gutter-ipad-landscape-up {
            .column {
                margin: 0 8% 0 0;
                width: 46%;

                &:nth-of-type(2n+2) {
                    margin: 0;
                }
            }
        }
    }
}

//sidebar
//wrappers

.two-columns {
    .column.sidebar,
    .column.left-sidebar {
        .btn {
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
        }
    }
}

@include bp(sm) {
    .two-columns {
        .column.sidebar {
            margin: 0 2% 0 0;
            width: 76%;

            &:nth-of-type(2n+2) {
                margin: 0;
                width: 22%;
            }
        }

        .column.left-sidebar {
            margin: 0 2% 0 0;
            width: 22%;

            &:nth-of-type(2n+2) {
                margin: 0;
                width: 76%;
            }
        }
    }

    .two-columns.wider {
        align-items: center;
        margin: 0;

        .column.sidebar {
            margin: 0 3% 0 0;
            width: 65%;

            &:nth-of-type(2n+2) {
                margin: 0;
                width: 32%;
            }
        }

        label {
            font-weight: normal;

            span {
                font-style: normal;
                font-weight: bold;
            }
        }
    }

    .two-column-map-map {
        width: 100%;
    }
}

//maps
//wrappers
.two-column-map-info {
    margin: 0 0 bsu(2) 0;
}

@include bp(md) {
    .two-column-map-info {
        margin: 0 3% 0 0;
        width: 30%;
    }

    .two-column-map-map {
        width: 67%;
    }
}

//map view info
.two-column-map-info {
    table {
        border-bottom: 1px solid $pistachio;
        margin: 0 0 bsu(1) 0;
    }

    .print-button {
        font-size: 16px;
        padding: bsu(0.25) bsu(1.25) bsu(0.5) bsu(1.25);
    }

    h2 {
        span {
            font-weight: 400;
            margin-left: 25%;
        }
    }
}

//flex columns
@include bp(sm) {
    .flex-columns-two-columns {
        .flex-column {
            width: 49%;
        }
    }
}