//new-subsection-nav
.new-subsection-nav-wrapper {
    margin: 0 0 20px 0;
    position: relative;

    &:after {
        background: $hub;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        width: 100%;
    }
}

.new-subsection-nav-toggle {
    align-items: center;
    background: $hub;
    border-radius: 4px 4px 0 0;
    color: $white;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    height: 30px;
    margin: 0;
    min-width: 155px;
    padding: 4px 30px 0 0;
    position: relative;
    transition: all $base-duration $base-timing;

    .u-icon-accordion {
        color: $white;
        display: inline-block;
        text-align: center;
        width: 43px;
        
        &:before {
            color: $white;
            left: 18px;
            position: absolute;
            top: 6px;
            transition: all $base-duration $base-timing;
        }
    }

    &.open {
        background: $hub;

        .u-icon-accordion {
            &:before {
                transform: rotate(90deg);
            }
        }
    }
}

.new-subsection-nav {
    display: none;
    left: 0;
    margin: 0;
    position: absolute;
    top: 30px;
    z-index: 5;

    li {
        list-style: none;
        margin: 0;

        a {
            background: $pistachio;
            border-bottom: 1px solid $hub;
            color: $white;
            display: block;
            min-width: 155px;
            padding: 10px 20px;

            &:hover {
                background: $marigold;
            }
        }

        &:last-of-type {
            a {
                border: none;
            }
        }
    }
}
    
@include bp(md) {
    .new-subsection-nav-wrapper {
        margin: 0 0 50px 0;
    
        &:after {
            display: none;
        }
    }

    .new-subsection-nav-toggle {
        display: none;
    }

    .new-subsection-nav {
        align-content: flex-start;
        align-items: flex-end;
        border-bottom: 2px solid $hub;
        display: flex;
        justify-content: flex-start;
        list-style: none;
        margin: 0 0 bsu(2) 0;
        position: relative;
    
        li {
            margin: 0 5px 0 0;
            text-align: center;
            width: auto;
    
            &:last-of-type {
                margin: 0;
            }
    
            a {
                background: $pistachio;
                border-radius: 7px 7px 0 0;
                color: $white;
                display: block;
                font-size: 14px;
                font-weight: 600;
                min-width: auto;
                overflow-wrap: break-word;
                padding: bsu(0.2) bsu(0.75);
                position: relative;
                transition: all $base-duration $base-timing;
    
                &:hover {
                    background: $marigold;
                }
    
                &.active {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }
    }
    
    .new-subsection-nav-wrapper {
        &.user-profile {    
            li {
                a {
                    &.user-profile {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.sites {    
            li {
                a {
                    &.sites {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.permissions {    
            li {
                a {
                    &.permissions {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.notifications {    
            li {
                a {
                    &.notifications {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.audit-access {
            li {
                a {
                    &.audit-access {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.audit-history {
            li {
                a {
                    &.audit-history {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.auditor-list {
            li {
                a {
                    &.auditor-list {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
        
        &.corrective-actions-pending {
            li {
                a {
                    &.corrective-actions-pending {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.corrective-actions-resolved {
            li {
                a {
                    &.corrective-actions-resolved {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.dashboard {    
            li {
                a {
                    &.dashboard {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
        
        &.setup {    
            li {
                a {
                    &.setup {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
        
        &.estimation {    
            li {
                a {
                    &.estimation {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
        
        &.climate {    
            li {
                a {
                    &.climate {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.industry-override {
            li {
                a {
                    &.industry-override {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.seasons {
            li {
                a {
                    &.seasons {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
        
        &.farm-details {    
            li {
                a {
                    &.farm-details {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.site {
            li {
                a {
                    &.site-setup {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.season {
            li {
                a {
                    &.season-setup {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.custom-maps {
            li {
                a {
                    &.custom-maps {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.field-data {
            li {
                a {
                    &.field-data {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.rei-phi {
            li {
                a {
                    &.rei-phi {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
                    
        &.crop-iq-listing {    
            li {
                a {
                    &.records {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.crop-iq-reports {    
            li {
                a {
                    &.reports {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.crop-iq-label-search {    
            li {
                a {
                    &.label-search {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.crop-iq-input-costs {    
            li {
                a {
                    &.input-costs {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.crop-iq-inventory {
            li {
                a {
                    &.inventory {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.crop-iq-company-products {    
            li {
                a {
                    &.company-products {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.crop-iq-spray-management {    
            li {
                a {
                    &.spray-management {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.crop-iq-recommendations {    
            li {
                a {
                    &.recommendations {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.crop-iq-tank-mixes {    
            li {
                a {
                    &.tank-mixes {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.supplier-listing {
            li {
                a {
                    &.supplier-listing {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.supplier-reports {
            li {
                a {
                    &.supplier-reports {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.supplier-requests {
            li {
                a {
                    &.supplier-requests {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.supplier-info {
            li {
                a {
                    &.supplier-info {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.products-supplied {
            li {
                a {
                    &.products-supplied {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.food-safety {
            li {
                a {
                    &.food-safety {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        //quality control
        &.lines li {
            a {
                &.lines {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.sizes li {
            a {
                &.sizes {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.defects li {
            a {
                &.defects {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.packaging li {
            a {
                &.packaging {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.brands li {
            a {
                &.brands {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.growers li {
            a {
                &.growers {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }
    
        //scouting nav
        &.scouting-create {    
            li {
                a {
                    &.create-report {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.scouting-reports-records {
            li {
                a {
                    &.reports {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.scouting-reports-view {
            li {
                a {
                    &.reports {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        .scouting-reports-subnav {
            display: none;
        }
    
        &.scouting-reports-records,
        &.scouting-reports-view {
    
        }
    
        //lab nav
        &.lab-order {    
            li {
                a {
                    &.order {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.lab-upload {
            li {
                a {
                    &.upload {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.lab-pending-approval {
            li {
                a {
                    &.pending-approval {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }
    
        &.lab-results {
            li {
                a {
                    &.results {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.lab-pending-approval {
            li {
                a {
                    &.pending-approval {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        //user nav
        &.users {
            li {
                a {
                    &.users {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.employees {
            li {
                a {
                    &.employees {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.roles {
            li {
                a {
                    &.roles {
                        background: $hub;
                        padding: bsu(0.3) bsu(0.75);
                    }
                }
            }
        }

        &.company {
            a {
                &.company {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.users {
            a {
                &.users {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.weatherstations {
            a {
                &.weatherstations {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.paymentdetails {
            a {
                &.paymentdetails {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.crops {
            a {
                &.crops {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.crop-groups {
            a {
                &.crop-groups {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.varieties {
            a {
                &.varieties {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.crm-search {
            a {
                &.crm-search {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.crm-task-board {
            a {
                &.crm-task-board {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.crm-settings {
            a {
                &.crm-settings {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.crm-dashboard {
            &.crm-dashboard {
                background: $hub;
                padding: bsu(0.3) bsu(0.75);
            }
        }

        &.crm-sopportunity-board {
            &.crm-opportunity-board {
                background: $hub;
                padding: bsu(0.3) bsu(0.75);
            }
        }

        &.crm-opportunity-board{
            &.crm-opportunity-board {
                background: $hub;
                padding: bsu(0.3) bsu(0.75);
            }
        }

        //calendar nav
        &.schedule {
            a {
                &.schedule {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.board {
            a {
                &.board {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }

        &.analysis {
            a {
                &.analysis {
                    background: $hub;
                    padding: bsu(0.3) bsu(0.75);
                }
            }
        }
    }
}

//new subnav
.crm-content-container .subnav-container {
    align-content: flex-start;
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-flow: row wrap;
    padding: 0;

    .add-buttons {
        align-content: flex-start;
        align-items: flex-end;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-flow: row wrap;
        width: 100%;
    }

    .btn {
        font-size: 16px;
        margin: 0 2% 15px 0;
        padding: 9px 10px 11px 10px;
        width: 49%;

        &:nth-of-type(2n+2),
        &:last-child {
            margin: 0 0 15px 0;
        }
    }
}

.subnav {
    align-content: flex-start;
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
	flex-flow: row wrap;
    list-style: none;
    margin: 0 0 20px 0;
    //width: 100%;

    li {
        margin: 0 10px 10px 0;

        &:last-of-type {
            margin: 0;
        }

        a {
            color: $emperor;
            font-size: 14px;
            padding: 0 10px 10px 10px;
            position: relative;

            &:after {
                background: $bitter-lemon;
                border-radius: 4px;
                bottom: 0;
                content: '';
                display: block;
                height: 4px;
				left: 50%;
				position: absolute;
				right: 50%;
				transition: all 0.3s ease-out 0.1s;
				//z-index: -1;
            }

            &:hover,
			&:active,
			&:focus {
                &:after {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

.crm-search {
    .subnav {
        li {
            a.crm-search {
                &:after {
                    background: $rio-grande;
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

.crm-settings {
    .subnav {
        li {
            a.crm-settings {
                &:after {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

.crm-task-board {
    .subnav {
        li {
            a.crm-task-board {
                &:after {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

.crm-dashboard .subnav {
    li {
        a.crm-dashboard {
            &:after {
                left: 0;
                right: 0;
            }
        }
    }
}

.crm-subscription-board {
    li {
        a.crm-subscription-board {
            &:after {
                left: 0;
                right: 0;
            }
        }
    }
}

.crm-opportunity-board {
    li {
        a.crm-opportunity-board {
            &:after {
                left: 0;
                right: 0;
            }
        }
    }
}

@include bp(sm) {
    .crm-content-container .subnav-container {
        align-items: flex-end;
        justify-content: flex-start;
        padding: 0 0 20px 0;
    
        .btn {
            max-width: 250px;
            width: auto;
    
            &:nth-of-type(2n+2) {
                margin: 0 2% 15px 0;
            }
        }
    }

    .subnav {
        justify-content: flex-start;

        li {
            margin-bottom: 0;
        }
    }
}

@include bp(md) {
    .subnav {
        width: 100%;
    }
}

@include bp(lg) {
    .crm-content-container .subnav-container {
        justify-content: space-between;

        .add-buttons {
            justify-content: flex-end;
            width: 40%;
        }
    }

    .subnav {
        width: 60%;
    }
}