$_form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$_form-box-shadow-focus: $_form-box-shadow, 0 0 5px rgba($action-color, 0.7);
//honeypot
input[type="text"]#surprise {
  display: none;
  //background: purple;
}

form {
  width: 100%;
}

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

.form-item {
  clear: both;
  position: relative;

  &.time {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: top;
    justify-content: center;

    label {
      width: 100%;
    }

    input {
      margin: 0 2% bsu(1) 0;
      width: 28%;

      #searchText {
        margin-bottom: 0;
      }
    }

    select {
      margin: 0 0 bsu(1) 0;
      text-align: center;
      width: 40%;
    }
  }

  &.two-inputs {
    display: flex !important;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: top;
    justify-content: space-between;

    label,
    h4,
    p {
      width: 100%;
    }

    input,
    select {
      min-width: 0;
      width: 48%;
    }
  }

  &.input-delete {
    display: flex !important;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    input {
      margin: 0 5px 0 0;
      width: 90%;
    }

    .u-icon-delete {
      cursor: pointer;
    }
  }
}

@include bp(md) {
  .form-item {
    &.two-inputs {
      input {
        background: none;
      }
    }
  }
}

//phone field
.phone-field {
  input {
    width: 15%;

    &:last-of-type {
      width: 83%;
    }
  }
}

@include bp(md) {
  .phone-field {
    input {
      width: 10%;

      &:last-of-type {
        width: 88%;
      }
    }
  }
}


label {
  display: block;
  font-size: 16px;
  font-weight: 600;
  //line-height: 1;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;

  span {
    //font-size: 11px;
    font-style: italic;

    &.bold {
      font-style: normal;
      font-weight: bold;
    }

    &.small {
      font-size: smaller;
      font-style: normal;
    }
  }

  &.big-label {
    font-size: 18px;
    font-weight: 600;
  }

  &.smaller {
    font-size: 13px;
    font-weight: normal;
  }
}

input,
select,
textarea {
  display: block;
  //font-family: $base-font-family;
  //font-size: 14px;
}

textarea {
  min-height: 100px;
}

input {
  &.datepicker,
  &.calendar-icon {
    background: $white url('../../Content/img/icons/newdesign-Calendar.svg') no-repeat 97% center;
    background-size: 23px 24px;
  }
}

input {
  &.timepicker-icon {
    background: $white url('../../Content/img/icons/newdesign-TimePicker.svg') no-repeat 97% center;
    background-size: 23px 24px;
  }
}

input[type="time"] {
  -webkit-appearance: none;
  background: none;
  background: $white url('../../Content/img/icons/newdesign-TimePicker.svg') no-repeat 97% center;
  background-size: 23px 24px;
  cursor: pointer;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}

#{$all-text-inputs},
select,
.k-breadcrumbs>.k-input,
input[type="decimal"] {
  appearance: none;
  background-color: $white;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: none;
  box-sizing: border-box;
  color: $almost-black;
  font-size: 16px;
  height: 44px;
  margin-bottom: bsu(0.75);
  padding: 12px 12px;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: $ada-grey-3;
  }

  &:focus {
    border-color: $pistachio;
    border-width: 2px;
    box-shadow: none;
    outline: none;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.66;
    -webkit-text-fill-color: $jumbo;
    
    &:hover {
      background: transparent;
      border: none;
    }
  }

  &::placeholder {
    color: $grey;
  }

  &.red-border {
    border: 1px solid $red-orange;
  }

  &.red {
    border: 1px solid $not-submitted;
    color: $not-submitted;
  }

  &.bg-grey {
    background: $ada-grey-5;
  }
}

.k-radio, 
input.k-checkbox {
  &:focus,
  &:active,
  &:focus-within {
    box-shadow: none !important;
    outline: none !important;
  }
}

.k-checkbox:focus+.k-checkbox-label:after {
  background: transparent !important;
  box-shadow: none !important;
}

input.k-checkbox {
  border: 1px solid $grey;
  border-radius: 46px;
  height: 20px;
  width: 20px;

  &:before {
    font-size: 18px;
    height: 20px;
    padding: 3px;
    width: 20px;
  }
}

.k-checkbox:checked, .k-checkbox.k-checked,
.k-checkbox:indeterminate, 
.k-checkbox.k-state-indeterminate, 
.k-checkbox.k-indeterminate {
  background-color: $mercury;
  border-color: $grey;
  color: $emperor;
}

input[type="time"] {
  padding: bsu(0.3) bsu(1) bsu(0.35) bsu(1);
}

@include bp(md) {
  #{$all-text-inputs},
  select,
  input[type="decimal"] {
    &:disabled,
    &.disabled {
      //padding: 0;
    }
  }

  input#searchText {
    margin-bottom: 0;
  }
}

// input[type="number"] {
//   padding: bsu(0.45) bsu(0) bsu(0.55) bsu(0.5);
// }

//file input styles
.input-file-wrapper {
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  p {
    margin: bsu(0.5) 0 bsu(1) 0;
  }
}

.inputfile + label {
  //line-height: 1;
	background: $hub;
	border: 0;
	border-radius: 5px;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-family: $base-font-family;
	font-size: 18px;
	-webkit-font-smoothing: antialiased;
	font-weight: 700;
	line-height: 1;
  margin: 0;
	outline: none;
	padding: 13px 25px 13px 25px;
	text-align: center;
	text-decoration: none;
	transition: all $base-duration $base-timing;
	user-select: none;
	vertical-align: middle;
  white-space: nowrap;
  position: relative;

	&:hover,
	&:focus {
		background: $hub-hover;
		color: $white;
		outline: none;
		outline-offset: 0;
	}

	&:visited {
		background: $hub;

		&:hover,
		&:focus {
			background: $marigold-hover;
		}
	}

  span {
    font-style: normal;
  }
}

.no-js .inputfile + label {
  display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label,
.inputfile:hover + label {
  background: $marigold;
  color: $white;
  outline: none;
  outline-offset: 0;
}

.inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}

@include bp(sm) {
  .input-file-wrapper {
    p {
      display: inline-block;
      margin: 0 0 bsu(1) 0;
      padding-left: bsu(1);
    }
  }
}

table {
  td {
    #{$all-text-inputs},
    select,
    input[type="decimal"] {
      margin-bottom: 0;
      width: 100%;
    }

    input[type="number"] {
      //padding: bsu(0.2) bsu(0) bsu(0.3) bsu(0.5);
    }
  }
}

table.cutty-sark-header {
  td {
    #{$all-text-inputs},
    select,
    input[type="decimal"] {
      //padding: bsu(0.45) bsu(0.5) bsu(0.48) bsu(0.5);
    }

    input[type="number"] {
      //padding: bsu(0.45) bsu(0) bsu(0.55) bsu(0.5);
    }
  }
}

//number inputs
input[type="number"] {
  -moz-appearance: textfield;
  height: 44px;
  position: relative;
  z-index: 1;
}

.number-input-max-width {
  max-width: 90px;
}

.uom {
  background: $ada-grey-5;
  display: inline;
  padding: 3px 7px;
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.u-icon-arrow-up5.step-up,
.u-icon-arrow-down5.step-down {
  background: $ada-grey-5;
  border: $base-border;
  border-left: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 23px;
  z-index: 2;

  &:before {
    color: $graphite-primary;
    font-size: 20px;
  }

  &.red {
    border-color: $not-submitted;
  }
}

.u-icon-arrow-up5.step-up {
  border-bottom: none;
  border-radius: 0 4px 0 0;
  height: 22px;
}

.u-icon-arrow-down5.step-down {
  border-top: none;
  border-radius: 0 0 4px 0;
  height: 21px;
  top: 23px;
}

select {
  background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
  background-size: 24px;
  color: $jumbo;
  width: 100%;

  &.borderless-left-arrow {
    background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat left 3px;
    background-size: 24px;
    border: none;
    font-weight: bold;
    padding: 0 0 0 20px;
  }

  &.selected {
    color: $almost-black;
  }

  &.select {
    background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
    background-size: 24px;
    color: $jumbo;
  }

  &:focus {
    //border-radius: 4px 4px 0 0;
  }
}



textarea {
  resize: vertical;

  &.flex-textarea {
    height: 54px;
  }
}

input[type="checkbox"],
input[type="radio"] {
  appearance: none;
  background: $white;
  border: 1px solid $grey;
  color: $grey;
  display: block;
  float: left;
  margin: 0 10px 0 0;
  position: relative;
  transition: all $base-duration $base-timing;

  &::before {
    transition: all $base-duration $base-timing;
  }

  ~ label {
    cursor: pointer;
    margin: 0 0 0 0;
    width: auto !important;
  }

  &.centered {
    float: none;
    margin: 0 auto !important;
  }
}

.form-flex {
  .form-item {
    input[type="checkbox"],
    input[type="radio"] {
      margin: 0 10px 10px 0 !important;

      &.centered {
        float: none;
        margin: 0 auto !important;
      }
    }
  }
}

input[type="checkbox"] {
  border-radius: 4px;
  height: 20px;
  margin: 2px 10px 0 0;
  width: 20px;

  &:checked:before {
    content: "\edaa";
    display: block;
    font-family: 'icomoon' !important;
    font-size: 16px;
    left: 1px;
    position: absolute;
    top: -1px;
    z-index: 2;
  }

  ~ label {
    //padding: 2px 0 0 0;
  }
}

input[type="radio"] {
  border-radius: 100%;
  height: 26px;
  min-width: 26px;
  width: 26px;

  &:checked:before {
    background: $marigold;
    border-radius: 100%;
    content: "";
    display: block;
    height: 18px;
    left: 3px;
    top: 3px;
    width: 18px;
    position: absolute;
    z-index: 2;
  }

  // ~ label {
  //   padding: 4px 0 0 0;
  // }
}

//crop iq application specific radios
#cropiq_application_information_block,
#cropiq_application_information_farm,
#cropiq_application_information_all {
  .button-container.section-top.checkbox-container {
    .styled-radio {
      display: none;
      height: 26px;
      left: 50%;
      margin: 0 0 20px 0px;
      opacity: 0;
      position: absolute;
      top: 10px;
      width: 26px;
      z-index: 1;

      & + label {
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
        margin: 0 10px 0 0;
        padding: 5px 0 0 30px;
        position: relative;
        z-index: 2;

        &:before {
          background: $white;
          border: 1px solid $grey;
          border-radius: 100%;
          content: '';
          display: block;
          height: 26px;
          left: 0;
          padding: 0 0 2px 4px;
          position: absolute;
          transition: all $base-duration $base-timing;
          top: 0;
          width: 26px;
        }
      }

      &:hover,
      &:focus {
        & + label {
          &:before {
            background: $white;
            border: 1px solid $grey;
          }
        }
      }

      &:checked {
        & + label {
          &:before {
            background: $white;
            border-radius: 100%;
            color: $grey;
            content: "";
            z-index: 1;
          }

          &:after {
            background: $marigold;
            border-radius: 100%;
            color: $grey;
            content: "";
            display: block;
            height: 18px;
            left: 4px;
            top: 4px;
            transition: all $base-duration $base-timing;
            width: 18px;
            position: absolute;
            z-index: 2;
          }
        }
      }

      &:hover + label:before {
        border: 1px solid $grey;
      }

      &:focus + label:before {
        border: 1px solid $grey;
      }
    }
  }
}

@include bp(sm) {
  #cropiq_application_information_block,
  #cropiq_application_information_farm,
  #cropiq_application_information_all {
    .button-container.section-top.checkbox-container.float-right {
      margin: 0;
    }
  }
}


table {
  th,
  td {
    input[type="checkbox"],
    input[type="radio"] {
      display: inline-block;
      float: none;
      margin: 0;
    }

    &.actions,
    &.na
    &[data-field="Select"] {
      text-align: center !important;

      input[type="checkbox"],
      input[type="radio"] {
        display: inline-block;
        float: none !important;
        margin: 0;
      }
    }
  }
}

.toggle-select-all {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-flow: row wrap;
  padding: 1px 0;

  h2 {
      font-size: 15px;
      font-weight: normal;
      margin: 0;
  }

  &.deselect-all {
    h2 {
      &.deselect-all {
        display: block;
      }

      &.select-all {
        display: none;
      }
    }
  }

  &.select-all {
    h2 {
      &.select-all {
        display: block;
      }

      &.deselect-all {
        display: none;
      }
    }
  }
}

//styled toggles
.toggle-switch {
  input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  input[type=checkbox]~label {
    cursor: pointer;
    text-indent: -9999px;
    width: 45px !important;
    height: 23px;
    background: $ada-grey-2;
    display: block;
    border-radius: 100px;
    position: relative;
    margin: 0;
  }

  label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 21px;
    height: 21px;
    background: $ada-grey-5;
    border-radius: 100%;
    transition: 0.3s;
  }

  input:checked + label {
    background: $marigold;
  }

  input:checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 21px;
  }

  &.visible-label {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-flow: row wrap;

    input[type=checkbox] {
      margin: 0;
    }

    p {
      margin: 0 0 0 10px;
      
      span {
        font-style: normal;
      }
    }
  }
}

.toggle-switch-with-label {
  padding-left: 55px;

  input[type="checkbox"] {
    display: none;
  }
  
  label {
    cursor: pointer;
    position: relative;
  }
  
  input[type="checkbox"] + label::before {
    content: ' ';
    display: block;
    width: 45px !important;
    height: 23px;
    border: 1px solid $ada-grey-2;
    border-radius: 100px;
    position: absolute;
    top: 1px;
    left: -54px;
    background: $ada-grey-2;
  }

  input[type="checkbox"]:checked + label::before {
    border: 1px solid $marigold;
    background: $marigold;
    transition: all $base-duration $base-timing;
  }
  
  input[type="checkbox"] + label::after {
    content: ' ';
    display: block;
    height: 21px;
    width: 21px;
    border: 1px solid $ada-grey-5;   
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: -53px;
    background: $ada-grey-5;
    transition: all $base-duration $base-timing;
  }
  
  input[type="checkbox"]:checked + label::after {
    left: -31px; 
    transition: all $base-duration $base-timing;
  }
}


@include bp(sm) {
}



//feedback stars
.feedback-stars {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-flow: row wrap;

  list-style: none;
  margin: 0 0 25px 0;

  li {
    display: block;
    height: 24px;
    margin: 0 10px 0 0;
    position: relative;
    width: 24px;
  }
}

.feedback-stars {
  .styled-radio {
    &.star-rating {
      & + label {
        display: block;
        height: 24px;
        width: 24px;
      }

      & + label:before {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        border: none;
        color: $mercury;
        content: "\ed1b";
        display: block;
        font-size: 24px;
        height: 24px;
        left: 0;
        padding: 0;
        top: 0;
        width: 24px;
      }

      &:checked + label:before,
      &.checked + label:before {
        border: none;
        background: transparent;
        color: $starship;
        content: "\ed1b";
      }

      &:checked + label:after {
        content: '';
        display: none;
      }

      &:hover + label:before {
        border: none;
        background: transparent;
        color: $starship;
      }

      &:focus + label:before {
        border: none;
        background: transparent;
        color: $starship;
        content: "\ed1b";
      }
    }
  }
}

///////////////////////////////////////////////
//end
///////////////////////////////////////////////


.horizontal-radios {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;

  input[type="checkbox"],
  input[type="radio"] {
    & ~ label {
      margin: 0 10px 0 0;
    }
  }

}

@include bp(md) {

}

input[type="file"] {
  margin-bottom: $small-spacing;
  padding: 6px 10px 7px 10px;
  width: 100%;
}


//site radios
.site-grid {
  a,
  label {
    display: block;
    margin: 0;
    position: relative;
    transition: border-color $base-duration $base-timing;
    
    &:before {
      border: 4px solid rgba($marigold, 0);
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      transition: border-color $base-duration $base-timing;
      width: 100%;
      z-index: 2;
    }

    &:hover {
      &:before {
        border: 4px solid rgba($marigold, 1);
      }
    }

    &.checked {
      &:before {
        border: 4px solid rgba($marigold, 1);
      }
    }
  }

  label {
    padding: 0;
  }

  input {
    visibility: hidden;
    position: absolute;
  }
}

//autocomplete
.easy-autocomplete {
  width: 100% !important;

  .sticky-footer{
    position: sticky;
    box-shadow: 0 0 8px 0 rgba($almost-black,0.25);
    padding-top: 6px;
    background-color: $white;
  }

  input {
    appearance: none;
    background-color: $white;
    border: $base-border;
    border-radius: $base-border-radius;
    box-shadow: none;
    box-sizing: border-box;
    color: $jumbo;  
    margin-bottom: bsu(0.75);
    padding: bsu(0.45) bsu(0.5) bsu(0.55) bsu(0.5);
    transition: border-color $base-duration $base-timing;
    width: 100%;

    &:hover {
      border-color: $pistachio;
    }

    &:focus {
      border-color: $pistachio;
      box-shadow: none;
      outline: none;
    }

    &:disabled,
    &.disabled {
      //background: transparent;
      //border: none;
      cursor: not-allowed;
      //padding: 2px 0 0 0;
      pointer-events: none;
      
      &:hover {
        background: transparent;
        border: none;
      }
    }

    &::placeholder {
      color: $grey;
    }
  }

  li {
    margin: 0;
  }
}

.easy-autocomplete-container {
  left: 0;
  position: absolute;
  top: 35px;
  width: 100%;
  z-index: 5;

  ul {
    border-bottom: 1px solid rgba($hub,0.5);
    list-style: none;
    margin: 0;
    max-height: 250px;
    overflow-y: scroll;
    
    li {
      background: $white;
      border-left: 1px solid rgba($hub,0.5);
      border-right: 1px solid rgba($hub,0.5);
      margin: 0;
      padding: 0 bsu(0.5);

      &:hover {
        color: $pistachio;
        cursor: pointer;
      }

      &:first-of-type {
        border-top: 1px solid rgba($hub,0.5);
        padding-top: bsu(0.5);
      }
      
      &:last-of-type {
        border-bottom: none;
        padding-bottom: bsu(0.5);
      }
    }
  }
}

//errors
#errors {
  display: none;
  text-align: left;
  
  &.active {
    display: block;
  }
}

//time wrapper {
.time-wrapper {
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: flex-start;

  input.hour,
  input.minutes {
    margin-right: 2%;
    width: 30%;
  }

  select {
    width: 36%;
  }
}

//from/to wrapper
.from-to,
.form-flex .form-item.from-to {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: top;
  justify-content: center;

  label {
    width: 100%;
  }

  input {
    margin: 0 0 bsu(0.75) 0;
    width: 43%;

    &:first-of-type {
      margin: 0 2% bsu(0.75) 0;
    }
  }

  span {
    margin: bsu(0.25) 2% bsu(0.75) 0;
    text-align: center;
    width: 10%;
  }
}

//form-flex 
.form-flex {
  &.flex-end {
    justify-content: flex-end !important;

    .form-item {
      &:last-of-type {
        margin-right: 0 !important;
      }
    }
  }

  .form-item {
    .btn,
    .button,
    label {
      width: 100%;

      &.btn-square {
        width: 25px;
      }
    }

    &.small-button {
      display: flex;
      flex-direction: row;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: space-between;

      label {
          width: 75%;
      }

      .button.add-button {
        border-radius: 3px;
        font-size: 12px;
        margin: 0;
        max-width: 80px;
        padding: bsu(0.25) bsu(0.5);
        text-align: left;
        width: 25%;
  
        &:after {
            font-size: 12px;
        }
      }
  
    }
    
    &.auto-width-buttons {
      .btn,
      .button {
        width: auto;
      }
    }
    
    &.radios {
      align-content: center;
      align-items: center;
      display: flex;
      justify-content: flex-start;
      min-height: 35px;

      label {
        padding: 0 20px 0 0;
      }
    }

    &.form-item-flex {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      flex-flow: row wrap;
      align-content: flex-end;

      label,
      p {
        margin: 0 4% bsu(1) 0;
        width: 48%;
      }

      p {
        font-size: 12px;
        font-style: italic;
        margin-right: 0;
      }
    }

    .form-flex.two-column {
      align-content: flex-start;
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;
  
      flex-direction: row;
      flex-wrap: wrap;
      flex-flow: row wrap;
  
      .form-item {
        margin: 0 2% 0 0;
        width: 49%;
  
        &:nth-of-type(2n+2) {
          margin: 0 0 0 0;
        }
      }
    }
  }

  &.two-column-mobile,
  &.two-column-mobile.three-column,
  &.three-column,
  &.four-column,
  &.four-column.two-column-mobile,
  &.five-column,
  &.five-column.two-column-mobile,
  &.six-column,
  &.eight-column {
    &.flex-end {
      justify-content: flex-end !important;

      .form-item {
        &:last-of-type {
          margin-right: 0 !important;
        }
      }
    }

    .form-item {
      &.full-width {
        margin: 0;
        width: 100%;
      }
    }
  }

  &.two-column.three-quarters {
    .form-item {
      margin-right: 10%;
      width: 70% !important;

      &:nth-of-type(2n+2) {
        width: 20% !important;
      }
    }
  }

  &.two-column-mobile {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;

    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;

    .form-item {
      margin: 0 2% 0 0;
      width: 49%;

      &:nth-of-type(2n+2) {
        margin: 0 0 0 0;
      }
    }
  }

  &.two-column-mobile.three-column {
    .form-item {
      margin-bottom: bsu(0.5);
    }
  }

  &.three-column {
    .form-item {
      .btn,
      .button {
        margin-top: 12px;
      }
    }

    &.radios {
      input[type="checkbox"],
      input[type="radio"] {
        margin: 0 10px 0 0 !important;
      }
    }
  }

  &.four-column.inner-padding {
    .form-item {
      margin-bottom: 20px;
    }
  }
}

@include bp(sm) {
  .form-flex {    
    p {
        padding: bsu(0.4) 0 bsu(0.55) 0;
    }

    &.two-column,
    &.two-column-tablet {
      align-content: flex-start;
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-flow: row wrap;
      justify-content: flex-start;
  
      .form-item {
        margin: 0 2% 0 0;
        width: 49%;
  
        &:nth-of-type(2n+2) {
          margin: 0 0 0 0;
        }
      }
    }

    .form-item {
      &.form-item-flex {
        label,
        p {
          margin: 18px 4% bsu(0.5) 0;
        }

        p {
          margin-right: 0;
          padding: 0;
        }
      }
    }
  }
}

//2 column inputs
@include bp(md) {
  .form-flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;

    align-content: flex-end;

    &.two-column.two-thirds {
      .form-item {
        //margin-right: 10%;
        width: 64%;
  
        &:nth-of-type(2n+2) {
          margin-right: 0;
          width: 34%;
        }
  
        .two-column .form-item {
          &:nth-of-type(2n+2) {
            width: 49%;
          }
        }
      }

      &.narrow-first {
        .form-item {
          width: 34%;
    
          &:nth-of-type(2n+2) {
            width: 64%;
          }
        }
      }
    }

    .form-item {
      display: inline-block;
      margin: 0 2% 0 0;
      width: 49%;

      &:nth-of-type(2n+2) {
        margin: 0 0 0 0;
      }

      &.u-flex {
        display: flex !important;
      }

      .button,
      .btn {
        width: 100%;
      }

      &.auto-width-buttons {
        .btn,
        .button {
          margin-top: 24px;
        }
      }

      &.full-width-buttons {
        .btn,
        .button {
          margin-top: 24px;
        }
      }
    }
  }
}

//3 column inputs
@include bp(sm) {
  .form-flex.three-column {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;

    align-content: flex-end;

    .form-item {
      margin: 0 2% 0 0;
      width: 32%;

      &:nth-of-type(3n+3) {
        margin: 0;
      }

      .btn,
      .button {
        margin-top: 22px;
      }

      &.two-column-span {
        margin: 0;
        width: 66%;

        &.first {
          margin: 0 2% 0 0;
        }

        &.second {
          margin: 0;
        }
      }
    }

    .form-flex.two-column {
      .form-item {
        margin: 0 2% 0 0;
        width: 49%;
  
        &:nth-of-type(2n+2) {
          margin: 0 0 0 0;
        }
      }
    }
  }

  .form-flex.two-column-mobile.three-column {
    .form-item {
      margin: 0 2% bsu(0.5) 0;
      width: 49%;

      .btn,
      .button,
      label,
      label span {
        font-size: 14px;
      }

      &:nth-of-type(2n+2) {
        margin: 0 0 0 0;
      }
    }

    .inputfile+label:after {
      font-size: 14px;
    }
  }
}

@include bp(md) {
  .form-flex.two-column-mobile.three-column {
    .form-item {
      margin: 0 2% 0 0;
      width: 32%;

      .btn,
      .button,
      label span {
        font-size: 18px;
      }

      &:nth-of-type(2n+2) {
        margin: 0 2% 0 0;
      }

      &:nth-of-type(3n+3) {
        margin: 0;
      }
    }

    .inputfile+label:after {
      font-size: 18px;
    }
  }
}

//4 column inputs
.form-flex.four-column.two-column-mobile {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;

  align-content: flex-end;

  .form-item {
    margin: 0 2% 0 0;
    width: 49%;

    &:nth-of-type(2n+2) {
      margin: 0 0 0 0;
    }

    .btn,
    button,
    a.button,
    input[type="submit"] {
      margin-top: 27px;
      width: 100%;
    }

    &.two-column-merge {
      width: 100%;
    }
  }
}

.form-flex.four-column.inner-padding {
  .form-item {
    margin-right: 0;
    padding: 0 20px 0 0;
    width: 50%;

    &:nth-of-type(2n+2) {
      margin-right: 0;
    }
  }
}

@include bp(sm) {
  .form-flex.four-column {
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content:  flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;

    align-content: flex-end;

    .form-item {
      margin: 0 2% 0 0;
      width: 49%;

      &:nth-of-type(2n+2) {
        margin: 0 0 0 0;
      }

      &.time {
        display: flex;
      }
    }

    .form-flex.two-column {
      .form-item {
        margin: 0 2% 0 0;
        width: 49%;
  
        &:nth-of-type(2n+2) {
          margin: 0 0 0 0;
        }
      }
    }
  }
}

.form-flex.four-column {
  button,
  .button,
  input[type="submit"] {
    margin-top: 21px;
    width: 100%;
  }
}

@include bp(md) {
  .form-flex.four-column,
  .form-flex.four-column.two-column-mobile {
    .form-item {
      margin: 0 2% 0 0;
      width: 23.5%;

      &:nth-of-type(2n+2) {
        margin: 0 2% 0 0;
      }

      &:nth-of-type(4n+4),
      &:last-of-type {
        margin: 0 0 0 0;
      }

      &.two-column-merge {
        margin: 0;
        width: 49%;
      }
    }
  }

  .form-flex.four-column.inner-padding {
    .form-item {
      width: 25%;

      &:first-of-type {
        padding-left: 32px;
      }

      &:nth-of-type(4) {
        padding-right: 20px;
      }
    }
  }
}

//5 column inputs
.form-flex.five-column.two-column-mobile {
  align-content: flex-end;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;

  .form-item {
    margin: 0 2% 0 0;
    width: 49%;

    &:nth-of-type(2n+2) {
      margin: 0 0 0 0;
    }

    &:last-of-type {
      margin: 0 0 0 0;
    }

    &.two-column-merge {
      width: 100%;
    }

    &.marketwatch-select-regions {
      margin: 0 0 0 0;
    }

    .btn,
    button,
    a.button,
    input[type="submit"] {
      margin: 22px 0 0 0;
      width: 100%;
    }
  }
}

@include bp(sm) {
  .form-flex.five-column,
  .form-flex.five-column.two-column-mobile {
    align-content: flex-end;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;

    .form-item {
      margin: 0 2% 0 0;
      width: 49%;

      &:nth-of-type(2n+2) {
        margin: 0 0 0 0;
      }
    }
  }
}

@include bp(md) {
  .form-flex.five-column,
  .form-flex.five-column.two-column-mobile {

    .form-item {
      margin: 0 2% 0 0;
      width: 18.4%;

      &:nth-of-type(2n+2) {
        margin: 0 2% 0 0;
      }

      &:nth-of-type(4n+4) {
        margin: 0 2% 0 0;
      }

      &:nth-of-type(5n+5) {
        margin: 0 0 0 0;
      }

      &:last-of-type {
        margin: 0 0 0 0;
      }
    }
  }
}

//six column
.form-flex.six-column {
  .btn,
    button,
    a.button,
    input[type="submit"] {
      margin: 22px 0 0 0;
      width: 100%;
    }
}

@include bp(sm) {
  .form-flex.six-column {
    .form-item {
      margin: 0 2% 0 0;
      width: 32%;

      &:nth-of-type(2n+2) {
        margin: 0 2% 0 0;
      }

      &:nth-of-type(3n+3) {
        margin: 0;
      }
    }
  }
}

@include bp(md) {
  .form-flex.six-column {
    .form-item {
      width: 15%;

      &:nth-of-type(3n+3) {
        margin: 0 2% 0 0;
      }

      &:nth-of-type(6n+6) {
        margin: 0;
      }
    }
  }
}

//eight column
.form-flex.eight-column {
  .btn,
    button,
    a.button,
    input[type="submit"] {
      margin: 22px 0 0 0;
      width: 100%;
    }
}

@include bp(sm) {
  .form-flex.eight-column {
    .form-item {
      margin: 0 2% 0 0;
      width: 32%;

      &:nth-of-type(2n+2) {
        margin: 0 2% 0 0;
      }

      &:nth-of-type(3n+3) {
        margin: 0;
      }
    }
  }
}

@include bp(md) {
  .form-flex.eight-column {
    .form-item {
      width: 10.75%;

      &:nth-of-type(3n+3) {
        margin: 0 2% 0 0;
      }

      &:nth-of-type(8n+8) {
        margin: 0;
      }
    }
  }
}

//add new select option
.add-new,
.add-new-input,
.add-new-close {
  display: none;
  opacity: 0;

  &.active {
    display: block;
    opacity: 1;
    animation: add-new-fade-in ease 2s forwards;
  }
}

.btn.add-new-close {
  position: absolute;
  right: 10px;
  top: 30px;
  width: 20px !important;
}

@keyframes add-new-view-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

//search field
.search-field-container {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;

  input {
    border-right: none;
    border-radius: 3px 0 0 3px;
    width: 85%;
  }

  a.search-icon-button {
    background: $pistachio;
    border-radius: 0 3px 3px 0;
    cursor: text;
    height: 44px;
    padding: 5px 0 0 0;
    pointer-events: none;
    position: relative;
    text-align: center;
    width: 15%;
    
    &:before {
      color: $white;
      content: "\e8b6";
      font-family: 'icomoon';
      font-size: 22px;
      font-weight: 600;
    }
  
    &:hover {
      background: $marigold;

      &:before {
        color: $white;
      }
    }
  }

  &.crm-search-field {
    margin: 0 0 20px 0;

    a.search-icon-button {
      background: $rio-grande;
      width: 40px;

      &:hover {
        background: $bitter-lemon;
  
        &:before {
          color: $white;
        }
      }
    }
  }
}

//dropdown list entry
.dropdown-name-list {
  border: $base-border;
  border-radius: 4px;
  list-style: none;
  margin: 0;

  li {
    display: flex !important;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    border-bottom: $base-border;
    height: 44px;
    padding: 0 12px;
    margin: 0;

    &:last-of-type {
      border-bottom: none;
    }

    input[type='text'] {
      background: transparent;
      border: none;
      border-radius: 0;
      margin: 0;
      padding: 0;
      width: 85%;
    }
  }
}

///////////////////////////////////////////////
//new design
///////////////////////////////////////////////
.new-design {
  #{$all-text-inputs},
  select,
  .k-breadcrumbs>.k-input,
  input[type="decimal"],
  .k-dateinput.teleric-blazor .k-picker-wrap.k-state-default input {
    border: $base-border;
    color: $mine-shaft;
    font-size: 16px;
    height: 44px;
    padding: 12px 12px;
    transition: border-color $base-duration $base-timing;

    &:hover {
      border-color: $ada-grey-3;
    }

    &:focus {
      border-color: $ada-grey-3;
    }

    &::placeholder {
      color: $grey;
    }

    &.red {
      border: 1px solid $not-submitted;
      color: $not-submitted;
    }

    &.bg-grey {
      background: $ada-grey-5;
    }
  }

  input {
    &.datepicker,
    &.calendar-icon {
      background: $white url('../../Content/img/icons/newdesign-Calendar.svg') no-repeat 97% 9px;
      background-size: 23px 24px;
    }
  }

  input {
    &.timepicker-icon {
      background: $white url('../../Content/img/icons/newdesign-TimePicker.svg') no-repeat 97% 9px;
      background-size: 23px 24px;
    }
  }

  //telerick blazor datepicker
  .k-header.k-datepicker {
    width: 100%;
    
    .k-picker-wrap.k-state-default {
      input {
        background: $white;
        border: $base-border;
        border-right: none;
        border-radius: 4px 0 0 4px;
        height: 44px;
        padding: 13px 12px;
      }
    }
  }

  .k-dateinput .k-dateinput-wrap {
    background-color: transparent;
  }

  .k-datepicker .k-picker-wrap.k-state-default>.k-select,
  .k-datepicker .k-picker-wrap.k-state-default>.k-button {
    background: $white url('../../Content/img/icons/newdesign-Calendar.svg') no-repeat left center;
    background-size: 24px;
    border: $base-border;
    border-left: none;
    border-radius: 0 4px 4px 0;
    width: 35px;
  }

  .k-datepicker .k-select {
    &:hover {
      background-color: transparent;
    }
  }

  // NEW DATEPICKER FORMATTING 02/22
  .k-datepicker {

    &.k-input {
      background: $white;
      border: $base-border;
      border-radius: 4px;
      box-shadow: none !important;
      height: 44px;
      padding: 13px 12px;
      width: 100%;

      .k-dateinput.k-input {
        background: transparent;
        border: none;
        box-shadow: none !important;
        border-radius: 0;
        height: auto;
        padding: 0;
        width: 100%;

        &:focus,
        &:focus-within {
          border: none;
        }
      }

      button {
        background: transparent;
        border: none;
        height: 24px;
        margin: 0 !important;
        padding: 0;
        position: absolute;
        right: 12px;
        top: 7px;
        width: 23px !important;
        z-index: 1;

        &:hover,
        &:active,
        &:visited {
          background: transparent;
          border: none;
          padding: 0;
        }

        span.k-icon.k-i-calendar.k-button-icon {
          background: $white url('../../Content/img/icons/newdesign-TimePicker.svg') no-repeat 0 0;
          background-size: 23px 24px;
          cursor: pointer;
          height: 24px;
          position: relative;
          width: 23px;
          z-index: 2;
        }
      }

      input {
        height: auto;
        border: none;
        padding: 0;
      }
    }
  }

  .k-i-calendar {
    &:before {
      content: '';
    }
  }

  .k-popup .k-widget.k-calendar {
    border: $base-border;
    width: 260px;
  }

  .telerik-blazor.k-calendar {
    font-size: 14px;
    padding: 5px 10px;

    .k-calendar-view {
      padding: 0;
      //width: 100%;
    }

    .k-button {
      &:hover {
        background: transparent;
      }
    }

    .k-popup .k-button {
      &:hover {
        background: transparent;
      }
    }

    table {
      tr {
        th, td {
          border-width: 0;
          font-size: 14px;
        }

        &:hover {
          th,
          td {
            background: transparent !important;
          }
        }
      }

      &.k-month {
        tr {
          th, 
          td {
            height: 32px;
            padding: 0;
            width: 32px;

            &:hover {
              background: $ada-grey-6;
            }

            &.k-state-selected,
            &.k-state-selected.k-state-focused {
              background: transparent;

              .k-link {
                background: $midgreen-bright;
                box-shadow: none;
              }
            }
          }

          &:hover {
            th,
            td {
              background: transparent !important;
            }
          }
        }
      }
    }

    .k-nav-today {
      color: $midgreen-bright;
    }

    .k-calendar-header {
      min-width: none;
      padding: 0;
      width: 240px;

      .k-button {
        border: none;
        color: $mine-shaft;
        padding: 0;

        &:hover {
          background: transparent !important;
        }

        .k-icon {
          &::before {
            color: $mine-shaft;
          }
        }
      }

      .k-button.k-calendar-title {
        border: none;
        color: $mine-shaft;
        font-size: 14px;
        padding: 0;

        &:hover {
          background: transparent;
        }
      }

      .k-calendar-nav {
        .k-button {
          padding: 0;
        }
      }
    }
  }

  textarea {
    min-height: 100px;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    height: 44px;
    position: relative;
    z-index: 1;
  }

  .number-input-max-width {
    max-width: 90px;
  }
  
  .uom {
    background: $ada-grey-5;
    display: inline;
    padding: 3px 7px;
  }

  input[type="number"]::-webkit-inner-spin-button, 
  input[type="number"]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  .u-icon-arrow-up5.step-up,
  .u-icon-arrow-down5.step-down {
    background: $ada-grey-5;
    border: $base-border;
    border-left: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 23px;
    z-index: 2;

    &:before {
      color: $graphite-primary;
      font-size: 20px;
    }

    &.red {
      border-color: $not-submitted;
    }
  }

  .u-icon-arrow-up5.step-up {
    border-bottom: none;
    border-radius: 0 4px 0 0;
    height: 22px;
  }

  .u-icon-arrow-down5.step-down {
    border-top: none;
    border-radius: 0 0 4px 0;
    height: 21px;
    top: 23px;
  }
}

//select and kendo typeahead
.new-design {
  select {
    background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
    background-size: 24px;
    color: $emperor;

    &.borderless-left-arrow {
      background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat left 3px;
      background-size: 24px;
      border: none;
      font-weight: bold;
      padding: 0 0 0 20px;
    }

    &.select {
      background: $white url('../../Content/img/icons/icon_arrow_down_light.png') no-repeat right center;
      background-size: 24px;
      color: $emperor;
    }
  }
}

body.new-design {
  input.k-checkbox {
    border: 1px solid $grey;
    border-radius: 46px;
    height: 20px;
    width: 20px;

    &:before {
      font-size: 18px;
      height: 20px;
      padding: 3px;
      width: 20px;
    }
  }

  .k-checkbox:checked, .k-checkbox.k-checked,
  .k-checkbox:indeterminate, 
  .k-checkbox.k-state-indeterminate, 
  .k-checkbox.k-indeterminate {
    background-color: $mercury;
    border-color: $grey;
    color: $emperor;
  }
}

//toggle select all
body.new-design {
  .toggle-select-all {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-flow: row wrap;
    padding: 1px 0;

    h2 {
        font-size: 15px;
        font-weight: normal;
        margin: 0;
    }

    &.deselect-all {
      h2 {
        &.deselect-all {
          display: block;
        }

        &.select-all {
          display: none;
        }
      }
    }

    &.select-all {
      h2 {
        &.select-all {
          display: block;
        }

        &.deselect-all {
          display: none;
        }
      }
    }
  }
}

//form flex
body.new-design {
  .form-flex.four-column.two-column-mobile {
    .form-item {
      .btn,
      button,
      a.button,
      input[type="submit"] {
        margin-top: 27px;
      }
    }
  }
}

//kendo type ahead combo box
//kendo typeahead search
.k-widget.k-combobox.k-header.k-combobox-clearable {
  background: transparent;
  border: none;
  height: 44px;

  .k-dropdown-wrap.k-state-default,
  .k-dropdown-wrap.k-state-hover {
    //background: $white url('../../Content/img/icons/icon-search.svg') no-repeat right center;
    background: $white;
    border: $base-border;
    color: $mine-shaft;
    font-size: 16px;
    height: 44px;
    padding: 0;

    input {
      background: transparent;
      height: 42px;
    }
  }

  .k-state-default>.k-select {
    border: none;
  }

  .k-i-arrow-60-down {
    //background: $white url('../../Content/img/icons/icon-search.svg') no-repeat 0 2px;
    background-size: 26px;
    height: 28px;
    width: 26px;
  }
}

@media print {
  input[type="radio"] {
    appearance: revert;
    &:checked::before {
      content: unset;
    }
  }
}
