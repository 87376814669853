#system-messages {
    @include container-padding;
    background-color: $concrete;
    box-shadow: bsu(0.25) bsu(1) bsu(2) rgba($jumbo, 0.66);
    width: 96%;
    max-width: $site-width;
    margin: 0 auto;
}

#system-success,
#system-fail {
    color: $white;
    display: none;
    font-size: 20px;
    font-weight: 600;
    min-height: 50px;
    padding: 10px 40px;
    text-align: center;
}

.system-messages {
    position: fixed;
    top: 57px;
    width: 100%;
    z-index: 1002;
}

#system-success {
    background: $pistachio;
}

#system-fail {
    background: $red;
}

//notification banners
.banner-warning,
.banner-alert {
    color: $white;
    font-size: 20px;
    font-weight: bold;
    padding: bsu(0.5);
    text-align: center;
    //text-transform: uppercase;

    a {
        color: $white;
        text-decoration: underline;
    }

    h1,
    h2 {
        color: $white;
        margin: 0;
        padding: 0;

        &:after {
            display: none;
        }
    }

    h1 {
        text-transform: uppercase;
    }
}

.banner-warning {
    background: $marigold;
}

.banner-alert {
    background: $red-orange;
}