.ui-widget {
    button,
    input,
    select,
    textarea {
        font-family: $base-font-family;
        font-size: 16px;
    }

    button {
        font-size: 18px;
    }
}

.ui-tabs {
    &.ui-widget-content {
        background: transparent;
    }

    .ui-widget-header {
        background: transparent;
        border: none;
        border-radius: 0;
        color: $ship-gray;
    }

    .ui-tabs-nav {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;

        padding: 0;

        li {
            float: none;
            margin: 0;

            a {
                padding: bsu(0.5) bsu(1);
            }

            &.ui-tabs-active {
                color: $ship-gray;

                a {
                    color: $ship-gray;
                }
            }
        }

        .ui-tabs-anchor {
            float: none;
            padding: 0;
        }
    }
}

.ui-tabs.colorful-tabs {
    .ui-tabs-nav {
        position: relative;
        z-index: 3;

        li {
            background: $hub;
            border-radius: 0;
            color: $white;
            position: relative;
            transition: all $base-duration $base-timing;
            width: 25%;

            a {
                color: $white;
                display: block;
                padding: bsu(1) bsu(0.5);
                text-align: center;
        
                svg,
                .u-icon-archive2 {
                    display: block;
                    height: 19px;
                    margin: 0 auto bsu(0.25) auto;
                    width: 24px;

                    &:before {
                        color: $white;
                        font-size: 24px;
                    }
                }

                span {
                    display: none;
                }
            }

            &:after {
                //background: $pistachio;
                bottom: 0;
                content: "";
                display: block;
                position: absolute;
                left: 50%;
                z-index: 100;
                margin-left: -10px;
                width: 0; 
                height: 0; 
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid $hub;
                transition: all $base-duration $base-timing;
            }

            &.ui-tabs-active {
                background: $marigold;
                color: $white;
                margin: 0;
                padding: 0;

                &:after {
                    bottom: -10px;
                    display: block;
                    border-top: 10px solid $marigold;
                    //animation: fade-in ease 0.35s forwards;
                    //opacity: 1;
                }

                a {
                    color: $white;
                }

                &:hover {
                    background: $marigold;

                    &:after {
                        border-top: 10px solid $marigold;
                    }
                }
            }

            &:hover {
                background: $pistachio;

                &:after {
                    border-top: 10px solid $pistachio;
                }
            }
        }
    }

    .ui-tabs-panel {
        opacity: 1;
        padding: bsu(2) 0 0 0;
    }

    #weather {
        border: none;
        margin: 0;
        padding: 0;
    }

    #closebutton4 {
        display: none;
    }

    &.company-documents {
        .ui-widget-content a.button {
            color: $white;
        }

        .ui-tabs-nav {
            li {
                background: $marigold;

                &:after {
                    border-top: 10px solid $marigold;
                }

                &.ui-tabs-active,
                &:hover {
                    background: $marigold;

                    &:after {
                        border-top: 10px solid $marigold;
                    }
                }

                a {
                    svg,
                    .u-icon-archive2 {
                        color: $white;
                        fill: $white;
                        font-size: 24px;
                        height: 24px;
                        width: 24px;
                    }
                }

                &:nth-of-type(2) {
                    background: $pistachio;

                    &:after {
                        border-top: 10px solid $pistachio;
                    }

                    &.ui-tabs-active,
                    &:hover {
                        background: $pistachio;

                        &:after {
                            border-top: 10px solid $pistachio;
                        }
                    }
                }

                &:nth-of-type(3) {
                    background: $hub;

                    &:after {
                        border-top: 10px solid $hub;
                    }

                    &.ui-tabs-active,
                    &:hover {
                        background: $hub;

                        &:after {
                            border-top: 10px solid $hub;
                        }
                    }
                }
            }
        }
    }
}

.ui-tabs.flat-tabs {
    .ui-tabs-nav {
        position: relative;
        z-index: 3;

        li {
            a {
                background: transparent;
                border-radius: 0;
                color: $almost-black;
                display: block;
                padding: 7px;
                position: relative;
                text-align: center;
                transition: all $base-duration $base-timing;
                width: 100%;
            }

            &.ui-tabs-active {
                margin: 0;
                padding: 0;
                
                a {
                    background: $pistachio;
                    color: $white;
                    font-weight: bold;
                }
            }
        }
    }
}


.ui-tabs.rounded-tabs {

    &.seasons-plantings {
        .ui-tabs-nav {
            li {
                max-width: none;
            }
        }
    }

    .ui-tabs-nav {
        position: relative;
        z-index: 3;

        li {
            max-width: 240px;
            white-space: normal;
            width: 50%;

            a {
                display: flex !important;
                flex-direction: column;
                justify-content: center;

                background: transparent;
                border: 1px solid $hub;
                border-bottom: none;
                border-radius: 6px 6px 0 0;
                color: $ship-gray;
                display: block;
                font-size: 18px;
                height: 50px;
                line-height: 1;
                margin-top: 5px;
                max-width: 100%;
                padding: 0 10px;
                position: relative;
                text-align: center;
                transition: all $base-duration $base-timing;
                width: 100%;
            }

            &.ui-tabs-active {
                margin: 0;
                padding: 0;
                
                a {
                    background: $hub;
                    border: none;
                    color: $white;
                    font-weight: bold;
                    height: 55px;
                    margin-top: 0;
                }
            }
        }
    }

    .tab-content {
        border: 1px solid $hub;
        border-radius: 0 0 6px 6px;
        padding: 35px 15px;

        .interior-padding {
            padding: 35px 15px;
        }
    }

    //this is specific to seasons-plantings based on design
    &.seasons-plantings {
        .ui-tabs-nav {
            li.active-mobile {
                margin: 0;
                padding: 0;
                
                a {
                    background: $hub;
                    border: none;
                    color: $white;
                    cursor: not-allowed;
                    pointer-events: none;
                    font-weight: bold;
                    height: 50px;
                    margin-top: 0;
                }
            }
        }

        .tab-content {
            &.active-mobile {
                display: block !important;
            }

            &.inactive-mobile {
                display: none !important;
            }
        }
    }
}



@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}


@include bp(sm) {
    .ui-tabs.colorful-tabs {
        .ui-tabs-nav {
            li {
                max-width: 175px;

                a {
                    span {
                        display: block;
                    }
                }
            }
        }
    }

    .ui-tabs.rounded-tabs {
        .tab-content {
            border: 1px solid $hub;
            border-radius: 0 6px 6px 6px;
            padding: 35px 25px;

            .interior-padding {
                padding: 35px 25px;
            }
        }
    }
}

@include bp(md) {
    .ui-tabs.rounded-tabs {
        //this is specific to seasons-plantings based on design
        &.seasons-plantings {
            .ui-tabs-nav {
                li.active-mobile {
                    margin: 0;
                    padding: 0;
                    
                    a {
                        background: transparent;
                        border: 1px solid $hub;
                        border-bottom: none;
                        color: $ship-gray;
                        cursor: inherit;
                        height: 50px;
                        margin-top: 5px;
                        pointer-events: inherit;
                    }

                    &.ui-tabs-active {
                        margin: 0;
                        padding: 0;
                        
                        a {
                            background: $hub;
                            border: none;
                            color: $white;
                            font-weight: bold;
                            height: 55px;
                            margin-top: 0;
                        }
                    }
                }
            }

            .tab-content {
                min-height: 445px;
            }

            #tabs-Blocks {
                display: block;
            }
            
            #tabs-Details {
                display: none;
            }
        }
    }
}

.company-documents-radios {
    label {
        margin-right: 20px;

        &:last-of-type {
            margin-right: 0;
        }
    }
}