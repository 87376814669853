//.ui-accordion {
.custom-maps-accordion {
    .ui-accordion-content {
        overflow: visible;
    }

    ul.multiselect {
        position: static;
        width: 65%;

        ul.block-color {
            li {
                border-bottom: 1px solid $concrete;
                float: left;
                width: 50%;

                label {
                    div {
                        display: inline-block;
                        height: 20px;
                        margin: 0 .625rem 0 0;
                        position: relative;
                        width: 20px;
                        z-index: 2;
                    }
                }
            }
        }
    }
}

.custom-maps-toggle-section {
    position: relative;

    .select-all {
        display: flex;
        position: absolute;
        right: bsu(1);
        top: 8px;
        z-index: 3;

        label {
            color: $white;
        }
    }
}
//}

@include bp(md) {
    .custom-maps-accordion {
        ul.multiselect {
            width: 100%;
        }
    }
}

//custom-maps-map
.c-map-container.custom-maps-map {
    background: $pistachio;
    height: 350px;
}

@include bp(sm) {
    .c-map-container.custom-maps-map {
        height: 400px;
    }
}

@include bp(md) {
    .c-map-container.custom-maps-map {
        height: 500px;
    }
}

@include bp(lg) {
    .c-map-container.custom-maps-map {
        height: 600px;
    }
}

//map tools
.map-zoom {
    right: 0;
    position: absolute;
    top: 7px;
    z-index: 2;

    ul {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;
        
        list-style: none;
        margin: 0;

        li {
            margin: 0 bsu(0.5) 0 0;

            a {
                background: rgba($white,0.8);
                border-radius: 3px;
                display: block;
                height: 30px;
                width: 30px;

                &:before {
                    font-family: 'icomoon' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;

                    color: $ship-gray;
                    display: block;
                    font-size: 16px;
                    padding: 7px 0 0 0;
                    position: relative;
                    text-align: center;
                }

                &.plus {
                    &:before {
                        content: "\ed94";
                    }
                }

                &.minus {
                    &:before {
                        content: "\ed95";
                    }
                }
            }
        }
    }
}

.map-tools {
    background: rgba($white,0.8);
    border-radius: 0 5px 5px 0;
    height: 335px;
    left: 0;
    margin-top: -168px;
    position: absolute;
    top: 50%;
    width: 38px;
    z-index: 2;

    h2 {
        border-radius: 5px 5px 0 0;
        color: $pistachio;
        cursor: pointer;
        display: none;
        font-weight: 500;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: bsu(0.5) bsu(0.5) bsu(0.25) bsu(0.5);

        li {
            margin: 0;
            padding: 0 0 bsu(0.25) 0;
            text-align: left;

            &:last-of-type {
                padding: 0;
            }

            a {
                color: $ship-gray;
                display: block;
                height: 25px;
                position: relative;
                width: 50px;

                span {
                    display: none;
                }

                &:before {
                    font-family: 'icomoon' !important;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;

                    color: $pistachio;
                    display: block;
                    font-size: 20px;
                    position: absolute;
                }

                &.map-zones-freeform {
                    &:before {
                        content: "\edea";
                        //content: "\e87b";
                        //content: "\e155";
                        //content: "\ea6c";
                    }
                }

                &.map-zones-block {
                    &:before {
                        content: "\ea6c";
                        //content: "\e25e";
                    }
                }

                &.water-source {
                    &:before {
                        content: "\e95c";
                    }
                }

                &.custom-pin {
                    &:before {
                        content: "\ea5f";
                    }
                }

                &.structure {
                    &:before {
                        content: "\e902";
                    }
                }

                &.route {
                    &:before {
                        content: "\edb0";
                    }
                }

                &.posting {
                    &:before {
                        content: "\ea71";
                    }
                }

                &.buffer-zone {
                    &:before {
                        content: "\e90b";
                    }
                }

                &.elevation {
                    &:before {
                        //content: "\ee05";
                        content: "\ee0d";
                    }
                }

                &.distance {
                    &:before {
                        content: "\ee36";
                    }
                }

                &.safe-zone {
                    &:before {
                        content: "\ed92";
                    }
                }
            }
        }
    }
}

@include bp(sm) {
    .map-zoom {
        right: 0;
        top: 10px;
    }

    .map-tools {
        height: 370px;
        left: -150px;
        margin-top: -185px;
        transition: all $base-duration $base-timing;
        width: 150px;

        &.open {
            left: 0;
        }

        h2 {
            background: rgba($white,0.8);
            display: block;
            height: 40px;
            margin-top: -75px;
            padding: bsu(0.5);
            position: absolute;
            text-align: center;
            top: 50%;
            right: -190px;
            width: 150px;
            
            /* Rotate from top left corner (not default) */
            -webkit-transform-origin: 0 0;
            -moz-transform-origin:    0 0;
            -ms-transform-origin:     0 0;
            -o-transform-origin:      0 0;
            
            -webkit-transform: rotate(90deg); 
            -moz-transform:    rotate(90deg); 
            -ms-transform:     rotate(90deg); 
            -o-transform:      rotate(90deg); 
        }

        ul {
            padding: bsu(0.25) 0 0 0;

            li {
                margin: 0;
                padding: 0;

                a {
                    height: auto;
                    padding: bsu(0.25) bsu(0.5) bsu(0.25) bsu(2.5);
                    width: 100%;

                    span {
                        display: block;
                        
                        span {
                            font-size: 10px;
                            font-style: italic;
                        }
                    }

                    &:hover {
                        background: rgba($white, 1.0);
                    }

                    &:before {
                        left: bsu(0.5);
                        margin-top: -10px;
                        top: 50%;
                    }
                }

                &:first-of-type {
                    a {
                        border-radius: 0 5px 0 0;
                    }
                }

                &:last-of-type {
                    a {
                        border-radius: 0 0 5px 0;
                    }
                }
            }
        }
    }
    
}

@include bp(md) {
    .map-tools {
        height: 455px;
        margin-top: -228px;

        ul {
            padding: 0 0 0 0;

            li {
                a {
                    padding: 5px 10px 5px 50px;                    
                }
            }
        }
    }   
}

//custom maps print
.c-map-container.custom-maps-map-print {
    height: 900px;
}

.compass-image {
    text-align: center;

    img {
        margin: 0 auto bsu(2) auto;
        width: 50%;
    }
}

.custom-map-legend-list {
    margin: 0 0 bsu(2) 0;

    ul {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;

        list-style: none;
        margin: 0;

        li {
            margin: 0 10% bsu(0.5) 0;
            padding: 0 0 0 50px;
            position: relative;
            width: 45%;

            &:nth-of-type(2n+2) {
                margin: 0 0 bsu(0.5) 0;     
            }

            &:before {
                font-family: 'icomoon' !important;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
    
                color: $pistachio;
                display: block;
                font-size: 20px;
                left: 0;
                position: absolute;
            }

            &.property-line,
            &.map-zones-freeform,
            &.map-zones-block,
            &.evacuation-route,
            &.route,
            &.buffer-zone,
            &.application-block {
                &:before {
                    content: "";
                    display: block;
                    height: 1px;
                    top: 8px;
                    width: 35px;
                }
            }
    
            &.property-line {
                &:before {
                    border: 2px solid #e29031;
                }
            }
    
            &.map-zones-freeform {
                &:before {
                    border: 2px solid $black;
                }
            }
    
            &.map-zones-block {
                &:before {
                    border: 2px solid $black;
                }
            }
            
            &.buffer-zone {
                &:before {
                    border: 2px dashed $black;
                }
            }
    
            &.application-block {
                &:before {
                    border: 2px solid $hub;
                }
            }
    
            &.buffer-zone-sign,
            &.water-source,
            &.custom-pin,
            &.structure,
            &.posting,
            &.elevation,
            &.distance,
            &.safe-zone,
            &.route {
                &:before {
                    left: 8px;
                }
            }
    
            
            &.buffer-zone-sign {
                &:before {
                    content: "\eea3";
                }
            }
    
            &.water-source {
                &:before {
                    content: "\e95c";
                }
            }
    
            &.custom-pin {
                &:before {
                    content: "\ea5f";
                }
            }
    
            &.structure {
                &:before {
                    content: "\e902";
                }
            }
    
            &.posting {
                &:before {
                    content: "\ea71";
                }
            }
    
            &.elevation {
                &:before {
                    content: "\ee0d";
                }
            }
    
            &.distance {
                &:before {
                    content: "\ee36";
                }
            }
    
            &.safe-zone {
                &:before {
                    content: "\ed92";
                }
            }

            &.route {
                &::before {
                    color: $black;
                    content: "\ee1b";
                    font-size: 25px;
                    font-weight: bold;
                    top: 0;
                }
            }
        }
    }
}

@include bp(sm) {
    .custom-map-legend {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;

        margin: 0 auto;
        max-width: 750px;
    }

    .compass-image {
        margin: 0 10% bsu(1) 0;
        width: 25%;

        img {
            width: 100%;
        }
    }

    .custom-map-legend-list {
        margin: 0 0 bsu(1) 0;
        width: 65%;
    }

    .custom-map-notes {
        width: 100%;
    }
}

@include bp(md) {
    .compass-image {
        margin: 0 4% bsu(1) 0;
        width: 16%;
    }

    .custom-map-legend-list {
        margin: 0 0 bsu(1) 0;
        width: 80%;

        ul {
            li {
                margin: 0 5% bsu(0.5) 0;
                width: 30%;

                &:nth-of-type(2n+2) {
                    margin: 0 5% bsu(0.5) 0;
                }

                &:nth-of-type(3n+3) {
                    margin: 0 0 bsu(0.5) 0;
                }
            }
        }
    }

    .custom-map-notes {
        margin-left: 20%;
        width: 80%;
    }
}

@media print {
    // @page {
    //     size: portrait;
    // }

    .custom-maps-print-screen {

    }
      

    .c-map-container.custom-maps-map-print {
        height: 6.5in !important;
        position: relative;
    }

    .custom-map-legend {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;

        h1 {
            font-size: 14px;
            margin: 0 0 0.0125in 0;
        }
    }

    .compass-image {
        margin: 0 5% 0.125in 0  !important;
        width: 15%  !important;

        img {
            margin: 0;
            width: 100%;
        }
    }

    .custom-map-legend-list {
        margin: 0 0 0.125in 0;
        width: 80%  !important;

        ul {
            li {
                font-size: 10px;
                margin: 0 5% 0.025in 0;
                width: 30%;

                &:nth-of-type(2n+2) {
                    margin: 0 5% 0.025in 0;
                }

                &:nth-of-type(3n+3) {
                    margin: 0 0 0.025in 0;
                }

                &:before {
                    font-size: 14px !important;
                }
            }
        }
    }

    .custom-map-notes {
        margin-left: 20%;
        width: 80%;
    }
}