@each $name, $value in $color-map {
    .u-text--#{$name} {
        color: $value;
    }
}

@each $name, $value in $color-map {
    .u-text-bg-glow--#{$name} {
        text-shadow: 0px 0px 6px rgba($value, 0.66);
    }
}