// Border Helpers
// -------------------------------------------------------------------------- */

@for $i from 0 through 5 {
    .u-border-#{$i} {
        border-style: solid !important;
        border-width: #{$i}px !important;
    }
    .u-border-#{$i}--top {
        border-top-style: solid !important;
        border-top-width: #{$i}px !important;
    }
    .u-border-#{$i}--right {
        border-right-style: solid !important;
        border-right-width: #{$i}px !important;
    }
    .u-border-#{$i}--bottom {
        border-bottom-style: solid !important;
        border-bottom-width: #{$i}px !important;
    }
    .u-border-#{$i}--left {
        border-left-style: solid !important;
        border-left-width: #{$i}px !important;
    }
}

//Border Colors
// -------------------------------------------------------------------------- */

@each $name, $value in $color-map {
    .u-border-color--#{$name} {
        border-color: $value;
    }
}

//Border Styles
// -------------------------------------------------------------------------- */
@each $name, $value in $border-style-map {
    .u-border-#{$name} {
        border-style: $value;
    }
}

// Border Radius Helpers
// ------------------------------------------------------------------------------------

@for $i from 0 through 10 {
    .u-border-radius-#{$i} {
        border-radius: #{$i}px !important;
    }
    .u-border-radius-left-#{$i} {
        border-top-left-radius: #{$i}px !important;
        border-bottom-left-radius: #{$i}px !important;
    }
    .u-border-radius-right-#{$i} {
        border-top-right-radius: #{$i}px !important;
        border-bottom-right-radius: #{$i}px !important;
    }
    .u-border-radius-top-left-#{$i} {
        border-top-left-radius: #{$i}px !important;
        }
    .u-border-radius-top-right-#{$i} {
        border-top-right-radius: #{$i}px !important;
    }
    .u-border-radius-bottom-right-#{$i} {
        border-bottom-right-radius: #{$i}px !important;
    }
    .u-border-radius-bottom-left-#{$i} {
        border-bottom-left-radius: #{$i}px !important;
    }
}

// Circle Images
// ------------------------------------------------------------------------------------

.u-bottom-radius--circle {
    border-radius: 50% !important;
}

// No Border
// ------------------------------------------------------------------------------------
.u-no-border {
    border: none !important;
}

//Green Bottom Borders
.hub-bottom-border {
    border-bottom: 2px solid $hub;
}

.pistachio-bottom-border {
    border-bottom: 1px solid $pistachio;
}

.killarney-bottom-border {
    border-bottom: 2px solid $killarney;
}

.cape-cod-bottom-border {
    border-bottom: 1px solid $cape-cod;
}

//Green Top Borders
.hub-top-border {
    border-top: 2px solid $hub;
}

.pistachio-top-border {
    border-top: 1px solid $pistachio;
}
// Table Border
// ------------------------------------------------------------------------------------
.table-border-bottom {
    position: relative;

    &:after {
        background: $ada-grey-4;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &.heavier {
        &:after {
            background: $ada-grey-4;
            height: 2px;
        }
    }
}

///////////////////////////////////////////////
//new design
///////////////////////////////////////////////
body.new-design {
    .table-border-bottom {
        position: relative;
    
        &:after {
            background: $ada-grey-4;
        }
    }
}
