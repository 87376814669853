// component containers

#datep {
    display: none;
}

.ui-widget {
    font-family: $base-font-family;
}

.ui-widget-content {    
    background: $concrete;
    border: none;
    border-radius: 5px 5px 0 0;
}

.ui-widget.ui-widget-content {
    border: none;
    padding: 0;
}

.ui-widget-header {
    background: $pistachio;
    border: 1px solid $pistachio;
    border-radius: 5px 5px 0 0;
    color: $white;
    font-weight: 600;

    a {
        color: $white;
    }
}

.ui-state-default, 
.ui-widget-content .ui-state-default, 
.ui-widget-header .ui-state-default, 
.ui-button, 
html .ui-button.ui-state-disabled:hover, 
html .ui-button.ui-state-disabled:active {
    background: transparent;
    border: none;
}

.ui-datepicker {
    display: none;
    max-width: 320px;
    width: 90%;
    z-index: 1002 !important;

    table {
        font-size: 14px;
    }
    
    .ui-datepicker-header {
        padding: 0;
        position: relative;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        display: block;
        height: 40px;
        margin: -20px 0 0 0;
        position: absolute;
        top: 50%;
        width: 40px;

        &:before {
            color: $white;
            font-family: 'icomoon';
            font-size: 25px;
            font-weight: 400;
            position: absolute;
            top: 0;
        }
    }

    .ui-datepicker-prev {
        left: 0;

        &:before {
            content: "\ee03";
            left: 5px;
        }
    }

    .ui-datepicker-next {
        right: 0;

        &:before {
            content: "\ee01";
            right: 5px;
        }
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {
        //display: none;

        span {
            background: none; 
            display: none;
        }
    }

    .ui-datepicker-title {
        line-height: 1.2;
        padding: bsu(0.6) 0 bsu(0.7) 0;
        text-align: center;

        display: flex;
        justify-content: center;

        select {
            // background: #fff url(../img/icons/icon_arrow_down.svg) no-repeat 95% 10px;
            // background-size: 12px;
            // border: none;
            // color: $ship-gray;
            // font-family: $base-font-family;
            // font-weight: bold;
            // padding: 5px 10px;
            //width: auto;

            &:first-of-type {
                margin: 0 10% 0 0;
            }
        }
    }

    select.ui-datepicker-month,
    select.ui-datepicker-year {
        width: 45%;
    }

    table {
        border-collapse: collapse;
        border-bottom: 1px solid $white;
        display: table;
        margin: 0;
        width: 100%;
        
        tr {
            display: table-row;
        }

        td,
        th {
            border: none;
            display: table-cell;
            padding: 0;
        }

        th {
            text-align: center;
        }

        td {
            border-bottom: 1px solid $white;
            border-top: 1px solid $white;
            border-right: 1px solid $white;

            &:last-of-type {
                border-right: none;
            }
            
            span,
            a {
                background: transparent;
                color: $ship-gray;
                display: block;
                padding: bsu(0.25) bsu(0.5);
                text-align: right;
            }
        }
    }

    .ui-datepicker-buttonpane {
        background-image: none;
        margin: .7em 0 0 0;
        padding: 0 .2em;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }

    .ui-datepicker-buttonpane button {
        float: right;
        margin: .5em .2em .4em;
        cursor: pointer;
        padding: .2em .6em .3em .6em;
        width: auto;
        overflow: visible;
    }

    .ui-datepicker-buttonpane button.ui-datepicker-current {
        float: left;
    }
}

@include bp(md) {
    .ui-datepicker {
        .ui-datepicker-prev,
        .ui-datepicker-next {
            display: block;
        }
    
        .ui-datepicker-title {
            line-height: 1.2;
            padding: bsu(0.6) 0 bsu(0.7) 0;
            text-align: center;
    
            display: flex;
            justify-content: center;
    
            select {
                // background: transparent;
                border: none;
                color: $ship-gray;
                font-family: $base-font-family;
                font-weight: bold;
                padding: 5px 10px;
                //width: auto;
    
                &:first-of-type {
                    margin: 0 10% 0 0;
                }
            }
        }
    }
}

// with multiple calendars
.ui-datepicker.ui-datepicker-multi {
	width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
	float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
	width: 95%;
	margin: 0 auto .4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
	width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
	width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
	width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
	border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
	clear: left;
}
.ui-datepicker-row-break {
	clear: both;
	width: 100%;
	font-size: 0;
}

// RTL support
.ui-datepicker-rtl {
	direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
	right: 2px;
	left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
	left: 2px;
	right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
	right: 1px;
	left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
	left: 1px;
	right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
	clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
	float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
	float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
	border-right-width: 0;
	border-left-width: 1px;
}

// icons
.ui-datepicker .ui-icon {
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;
	left: .5em;
	top: .3em;
}

.ui-datepicker {
    .ui-datepicker-today {
        .ui-state-highlight,
        .ui-widget-content .ui-state-highlight,
        .ui-widget-header .ui-state-highlight {
            background: $iron;
            color: $ship-gray;
        }
    }

    .ui-datepicker-current-day {
        .ui-state-default.ui-state-active {
            background: $hub;
            color: $white;
        }
    }
}




// Interaction Cues

.ui-state-default, 
.ui-widget-content .ui-state-default, 
.ui-widget-header .ui-state-default, 
.ui-button, 
html .ui-button.ui-state-disabled:hover, 
html .ui-button.ui-state-disabled:active {
    color: $ship-gray;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	background: $hub;
	color: $white;
}
.ui-state-checked {
	background: $hub;
}
.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
	color: $white;
}
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
	border: 1px solid #cd0a0a;
	//background: #b81900 url("/../../vendor/jquery-ui/themes/ui-lightness/images/ui-bg_diagonals-thick_18_b81900_40x40.png") 50% 50% repeat;
	color: #ffffff;
}
.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
	color: #ffffff;
}
.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
	color: #ffffff;
}
.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
	font-weight: bold;
}
.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
	opacity: .7;
	filter:Alpha(Opacity=70); /* support: IE8 */
	font-weight: normal;
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
	opacity: .35;
	filter:Alpha(Opacity=35); /* support: IE8 */
	background-image: none;
}
.ui-state-disabled .ui-icon {
	filter:Alpha(Opacity=35); /* support: IE8 - See #6059 */
}

// icons

/* states and images */
.ui-icon {
	width: 16px;
	height: 16px;
}
.ui-icon,
.ui-widget-content .ui-icon {
	//background-image: url("/../../vendor/jquery-ui/themes/ui-lightness/images/ui-icons_222222_256x240.png");
}
.ui-widget-header .ui-icon {
	//background-image: url("/../../vendor/jquery-ui/themes/ui-lightness/images/ui-icons_ffffff_256x240.png");
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon,
.ui-button:hover .ui-icon,
.ui-button:focus .ui-icon {
	//background-image: url("/../../vendor/jquery-ui/themes/ui-lightness/images/ui-icons_ef8c08_256x240.png");
}
.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
	//background-image: url("/../../vendor/jquery-ui/themes/ui-lightness/images/ui-icons_ef8c08_256x240.png");
}
.ui-state-highlight .ui-icon,
.ui-button .ui-state-highlight.ui-icon {
	//background-image: url("/../../vendor/jquery-ui/themes/ui-lightness/images/ui-icons_228ef1_256x240.png");
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
	//background-image: url("/../../vendor/jquery-ui/themes/ui-lightness/images/ui-icons_ffd27a_256x240.png");
}
.ui-button .ui-icon {
	//background-image: url("/../../vendor/jquery-ui/themes/ui-lightness/images/ui-icons_ef8c08_256x240.png");
}

.ui-datebox-datebox-groups {
    display: flex!important;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    .ui-datebox-datebox-group {
        width: auto!important;
        flex: 0 1 33%;

        &:nth-child(1) {
            width: 50%!important;
        }

        &:nth-child(2),
        &:nth-child(3) {
            width: 25%!important;
        }
    }
}
