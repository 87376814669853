.location-buttons-container {
    display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: center;
    justify-content: center;
    
    input {
        &:first-of-type {
            margin: 0 0 bsu(1) 0;
        }
    }
}

@include bp(sm) {
    .location-buttons-container {
        justify-content: flex-start;

        input {
            &:first-of-type {
                margin: 0 bsu(1) 0 0;
            }
        }
    }
}