.utility-nav {
    position: relative;

    a.open {
        color: $hub;
    }

    &.alerts {
        ul.c-utility-nav__menu {
            max-width: 300px;
            min-width: 300px;

            &:before {
                color: $hub;
            }

            li {
                a {
                    color: $pistachio;
                    font-size: 12px;
                    line-height: 1.5;
                    padding: bsu(0.5) bsu(0.5);
                    text-align: left;
                    word-break: break-word;

                    &:hover {
                        color: $marigold;
                    }

                    &.red {
                        color: $red-orange;
                    }
                }

                &:first-of-type {
                    a {
                        background: $hub;
                        border-radius: 5px 5px 0 0;
                        color: $white;
                        font-size: 16px;

                        &:hover {
                            background: $pistachio;
                            color: $white;
                        }
                    }
                }

                &:last-of-type {
                    a {
                        background: $marigold;
                        color: $white;
                        font-size: 16px;
                        font-style: italic;
                        text-align: center;
                    }
                }
            }
        }
    }
}

ul.c-utility-nav__menu {
    background: $concrete;
    //border-top: 1px solid $concrete;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 10px rgba($gray-chateau, 0.2);
    display: none;
    list-style: none;
    margin: 0;
    position: absolute;
    right: -10px;
    top: 107%;
    min-width: 200px;
    z-index: 1;

    &:before {
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        color: $concrete;
        content: "\ee00";
        display: block;
        font-size: 40px;
        position: absolute;
        right: -3px;
        top: -24px;
    }

    li {
        border-bottom: 1px solid $white;
        padding: bsu(0.25) bsu(0.5);
        text-align: left;
        
        a {
            font-size: 16px;
            line-height: bsu(1.5);
            text-transform: none;
            //padding: bsu(0.25) bsu(0.5);

            span {
                color: $pistachio;
            }

            &:hover {
                color: $hub;
            }
        }

        &.user-info {
            line-height: 1;
            padding: bsu(0.25) bsu(0.5);

            p,
            a {
                margin: 0;
                padding: 0;
            }

            p {
                font-size: 18px;
                font-weight: 600;
            }

            a {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

body.new-design {
    ul.c-utility-nav__menu {
        &::before {
            display: none;
        }
    }
}

@include bp(sm) {
    ul.c-utility-nav__menu {
        right: -4px;

        &:before {
            right: 7px;
        }
    }
}

@include bp(md) {
    ul.c-utility-nav__menu {
        right: 5px;

        &:before {
            right: -6px;
        }
    }
}

@include bp(lg) {
    ul.c-utility-nav__menu {
        &:before {
            right: 7px;
        }
    }
}

@include bp(xlg) {
    ul.c-utility-nav__menu {
        right: 20px;

        &:before {
            right: 12px;
        }
    }
}