.ui-helper-hidden-accessible {
    display: none;
}

.ui-tooltip-content {
    border: none;
    padding: 20px;
}

.ui-tooltip, .arrow:after {
    background: $white;
    border: none;
}

// .arrow {
//     width: 70px;
//     height: 16px;
//     overflow: hidden;
//     position: absolute;
//     left: 50%;
//     margin-left: -35px;
//     bottom: -16px;

//     &.top {
//         top: -16px;
//         bottom: auto;
        
//         &:after {
//             bottom: -20px;
//             top: auto;
//         }
//     }

//     &.left {
//         left: 50%;
//         margin-left: -35px;
//     }

//     &:after {
//         content: "";
//         margin-left: -10px;
//         position: absolute;
//         left: 50%;
//         top: -20px;
//         width: 25px;
//         height: 25px;
//         box-shadow: 6px 5px 9px -9px black;
//         -webkit-transform: rotate(45deg);
//         -ms-transform: rotate(45deg);
//         transform: rotate(45deg);
//     }
// }

    
.ui-tooltip {
    border-radius: 5px;
    box-shadow: 0 0 5px 3px rgba($ship-gray, 0.2);
    padding: 0;
    max-width: 380px !important;
    width: auto !important;

    .tooltip-container {
        box-shadow: none;
        padding: 0;
    }

    table {
        box-shadow: none;
        margin: 0;
        
        tr {
            background: $white;

            &:nth-of-type(2n+2) {
                background: $concrete;
            }

            th,
            td {
                font-size: 12px;
                padding: 5px;
                width: 20%;

                &:last-of-type {
                    width: 40%;
                }
            }

            td {
                font-size: 12px;
            }
        }
    }

    p {
        margin: 0;
    }
}

body .ui-tooltip {
	border-width: 0;
}

.custom-tooltip {
    background: transparent;
    border: none;
    box-shadow: none;
    color: $white;
    font-weight: 600;
}

@include bp(md) {
    .current-application {
        tr {
            td {
                &.tooltip {
                    color: $marigold;
                    cursor: pointer;
                }

                &:nth-child(2) {
                    &.tooltip {
                        color: $marigold;
                        cursor: pointer;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

//new design (quality control log builder)
.log-builder-tools {
    .ui-tooltip {
        background: $mercury;
        border: none !important;
        box-shadow: none !important;

        .ui-tooltip-content {
            border: none !important;
            box-shadow: none !important;
            display: flex;
            align-items: center;
            height: 47px;
            padding: 0 10px;
        }
    }
}