.parsley-error {
    margin-bottom: 0!important;
    border-color: $danger-color;
    border-bottom: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.parsley-errors-list.filled {
    background-color: $warning-color;
    padding: bsu(0.25);
    margin-bottom: bsu(1);
    border: 1px solid $danger-color;
    border-top: 1px solid $almost-black;
    border-bottom-left-radius: $base-border-radius;
    border-bottom-right-radius: $base-border-radius;
    list-style: none;
    margin: 0;

    li {
        list-style: disc;
        margin-left: 2rem;
    }
}
