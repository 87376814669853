ul,
ol {
  margin: 0 0 bsu(0.75) bsu(1);
  padding: 0;

  li {
    margin: 0 0 bsu(0.25) 0;

    span {
      font-weight: 600;
    }
  }

  &.no-list-style {
    list-style: none;
    margin: 0;
  }
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}

.rendered-html-container {
  ul,
  ol {
    margin: 0 0 bsu(0.75) bsu(1.25);

    li {
      span {
        font-weight: normal;
      }
    }
  }

  dt {
    font-weight: normal;
  }
}

//flex list
ul.flex-list {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;

  li {
    overflow-wrap: break-word;
    margin-right: 4%;
    width: 48%;

    &:nth-of-type(2n+2) {
      margin-right: 0;
    }

    h3 {
      margin: 0;
    }
  }

  &.two-column {
    li {
      margin-right: 4%;
      width: 48%;
  
      &:nth-of-type(2n+2) {
        margin-right: 0;
      }
    }
  }

  &.three-column {
    li {
      margin-right: 0;
      width: 100%;
    }
  }
}

@include bp(sm) {
  ul.flex-list {
    li {
      margin-right: 2%;
      width: 32%;
  
      &:nth-of-type(2n+2) {
        margin-right: 2%;
      }

      &:nth-of-type(3n+3) {
        margin-right: 0;
      }
    }
  }
}

@include bp(md) {
  ul.flex-list {
    li {
      margin-right: 4%;
      width: 22%;
  
      &:nth-of-type(2n+2) {
        margin-right: 4%;
      }

      &:nth-of-type(3n+3) {
        margin-right: 4%;
      }

      &:nth-of-type(4n+4) {
        margin-right: 0;
      }
    }

    &.three-column {
      li {
        margin-right: 4%;
        width: 30%;

        &:nth-of-type(3n+3) {
          margin-right: 0;
        }
      }
    }
  }
}

//flex list with deletes
//upload attachments section
ul.delete-list {
  list-style: none;
  margin: 0 0 bsu(1) 0;

  li {
    a {
      font-style: italic;
      text-decoration: underline;

      &.delete,
      &.modal-link {
        font-style: normal;
        margin: 0 0 0 bsu(0.5);
        text-decoration: none;

        .u-icon-delete {
          &:before {
          top: 3px;
          }
        }
      }
    }
  }

  &.crm {
    li {
      background: $mercury;
      border-radius: 4px;
      margin-bottom: bsu(0.5);
      padding: 8px 25px 8px 15px;
      position: relative;
  
      a {
        &.delete,
        &.modal-link {
          .u-icon-close {
            &:before {
              font-size: 18px;
              position: absolute;
              right: 10px;
              top: 9px;
            }
          }
        }
      }
    }
  }
}


//svg list
ul.svg-list {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;

  list-style-type: none;
  margin: 0 0 bsu(1) 0;

  h1 {
    width: 100%;
  }

  li {
    margin: 0 16% bsu(1) 0;
    position: relative;

    text-align: center;
    width: 42%;

    &:nth-of-type(2n+2),
    &:last-of-type {
      margin: 0 0 bsu(1) 0;                
    }

    a {
      display: block;
      padding: 0;

      svg {
        height: auto;
        margin: bsu(1) 0 bsu(0.5) 0;
        max-height: 80px;
        max-width: 80px;
        width: auto;
      }

      h2 {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  &.two-column {
    li {
      margin: 0 4% 0 0;
      width: 48%;

      &:nth-of-type(2n+2),
      &:last-of-type {
        margin: 0;
      }

      a {  
        svg {
          margin: bsu(1) auto bsu(0.5) auto;
        }

        section {
          padding: 0;
        }
      }
    }
  }

  &.three-column {
    align-items: flex-end;
    justify-content: center;
    margin: 0;

    li {
      margin: 0 4% 0 0;
      width: 28%;

      &:first-of-type {
        svg {
          margin-bottom: 3px;
          max-height: 28px;
          max-width: 28px;
        }
      }

      &:nth-of-type(2n+2) {
        margin: 0 4% 0 0;
      }

      &:last-of-type {
        margin: 0;
      }

      svg {
        margin: 0;
        max-height: 35px;
        max-width: 35px;
        width: auto;
      }

      h2 {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  &.four-column.four-column-mobile {
    margin: 0;

    li {
      margin: 0 2% 0 0;
      width: 23.5%;

      &:nth-of-type(4n+4) {
        margin: 0;
      }

      a {
        text-align: center;

        svg {
          margin: 0 auto;
          width: 70%;
        }

        h2 {
          font-size: 14px;
        }
      }

      &:first-of-type {
        a {
          svg {
            margin: 6px auto 5px auto;
            width: 55%;
          }
        }
      }
    }
  }

  &.seven-column {
    li {
      margin: 0 8% bsu(1) 0;
      width: 28%;
  
      &:nth-of-type(2n+2) {
        margin: 0 8% bsu(1) 0;               
      }

      &:nth-of-type(3n+3),
      &:last-of-type {
        margin: 0 0 bsu(1) 0;                
      }

      a {
        svg {
          max-height: 50px;
          max-width: 50px;
        }

        h2 {
          font-size: 14px;
        }
      }
    }
  }

  &.five-column {
    li {
      transition: all $base-duration $base-timing;
      
      a {

        svg {
          margin: bsu(1) 0 bsu(0.5) 0;
          //max-height: 80px;
          width: 80px;
        }

        h2,
        p {
          color: $ship-gray;
          margin: 0;
        }
      }
    }
  }
  
  &.five-column.view-list {
    display: block;
    flex-direction: none;
    flex-flow: none;
  
    li {
      margin: 0 0 bsu(1.5) 0;
      text-align: left;
      transition: all $base-duration $base-timing;
      width: 100%;
  
      &:nth-of-type(2n+2),
      &:last-of-type {
        margin: 0 0 bsu(1.5) 0;              
      }

      a {
        svg,
        section {
          display: inline-block;
          vertical-align: middle;
        }

        svg {
          max-height: 50px;
          max-width: 50px;
          margin: 0 bsu(0.5) 0 0;
        }
      }
    }
  }
}

@include bp(sm) {
  ul.svg-list {
    &.four-column,
    &.five-column {
      li {
        margin: 0 16% bsu(1) 0;
        width: 22.667%;

        &:nth-of-type(2n+2) {
          margin: 0 16% bsu(1) 0;                
        }

        &:nth-of-type(3n+3),
        &:last-of-type {
          margin: 0 0 bsu(1) 0;                
        }
      }
    }

    &.four-column.four-column-mobile {
      li {
        &:nth-of-type(2n+2) {
          margin: 0 2% 0 0;          
        }

        &:nth-of-type(4n+4) {
          margin: 0;          
        }

        a {
          h2 {
            font-size: 12px;
          }
        }

        &:first-of-type {
          a {
            svg {
              margin: 4px auto 4px auto;
            }
          }
        }
      }
    }
  

    &.seven-column {
      li {
        margin: 0 4% bsu(1) 0;
        width: 22%;
    
        &:nth-of-type(2n+2) {
          margin: 0 4% bsu(1) 0;               
        }
  
        &:nth-of-type(3n+3) {
          margin: 0 4% bsu(1) 0;                
        }

        &:nth-of-type(4n+4),
        &:last-of-type {
          margin: 0 0 bsu(1) 0;                
        }
      }
    }
  }
}

@include bp(md) {
  ul.svg-list {
    li {
      margin: 0 16% bsu(1) 0;
      width: 22.667%;

      &:nth-of-type(2n+2) {
        margin: 0 16% bsu(1) 0;                
      }

      &:nth-of-type(3n+3),
      &:last-of-type {
        margin: 0 0 bsu(1) 0;                
      }
    }

    &.two-column {
      li {
        &:nth-of-type(3n+3) {
          margin: 0 4% 0 0;
        }
      }
    }

    &.four-column {
      li {
        width: 13%;

        &:nth-of-type(3n+3) {
          margin: 0 16% bsu(1) 0;                
        }

        &:nth-of-type(4n+4),
        &:last-of-type {
          margin: 0 0 bsu(1) 0;                
        }
      }
    }

    &.four-column.four-column-mobile {
      li {
        a {
          h2 {
            font-size: 14px;
          }
        }

        &:nth-of-type(3n+3) {
          margin: 0 2% 0 0;          
        }

        &:first-of-type {
          a {
            svg {
              margin: 5px auto 5px auto;
            }
          }
        }
      }
    }

    &.five-column {
      li {
        margin: 0 5% bsu(1) 0;  
        width: 16%;

        &:nth-of-type(2n+2) {
          margin: 0 5% bsu(1) 0;                
        }
  
        &:nth-of-type(3n+3) {
          margin: 0 5% bsu(1) 0;                
        }

        &:nth-of-type(5n+5),
        &:last-of-type {
          margin: 0 0 bsu(1) 0;                
        }
      }
    }

    &.seven-column {
      li {
        margin: 0 5% bsu(1) 0;
        width: 10%;
    
        &:nth-of-type(2n+2) {
          margin: 0 5% bsu(1) 0;               
        }
  
        &:nth-of-type(3n+3) {
          margin: 0 5% bsu(1) 0;                
        }

        &:nth-of-type(4n+4) {
          margin: 0 5% bsu(1) 0;                
        }

        &:nth-of-type(7n+7),
        &:last-of-type {
          margin: 0 0 bsu(1) 0;                
        }
      }
    }
  }
}

//two column list
ul.two-column-list {
  list-style: none;
  margin: 0;

  li {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;

    div {
      font-weight: 500;
      margin: 0 4% bsu(0.5) 0;
      width: 48%;

      &:last-of-type {
        margin: 0 0 bsu(0.5) 0;
        text-align: right;
      }
    }
  }
}

ul.striped-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    background: transparent;
    margin: 0;
    padding: bsu(0.75);

    &:nth-of-type(2n+2) {
      background: $concrete;
    }
  }

  &.green-header {
    li {
      &:first-of-type {
        background: $hub;
        color: $white;
        font-weight: bold;
      }
    }
  }

  &.less-padding {
    li {
      padding: 7px;
    }
  }
}

ul.link-list {
  li {
    a { 
      color: $ship-gray;
      font-size: 16px;
      position: relative;
      //text-decoration: underline;

      &:after {
        background: $pistachio;
        bottom: -2px;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        transition: all $base-duration $base-timing;
        width: 100%;
      }

      &:hover {
        color: $marigold;

        &:after {
          background: $marigold;
        }
      }
    }
  }
}

// four column list
ul.four-column-list {
  column-width: 150px;
  list-style: none;
  margin: 0 0 bsu(1) 0;

  li {
    margin: 0 0 bsu(0.5) 0;

    a {
      color: $ship-gray;
      font-size: 15px;
      font-weight: 500;
      text-decoration: underline;

      &:hover {
        color: $marigold;
      }
    }
  }
}

@include bp(md) {
  ul.four-column-list {
    column-width: 200px;
  }
}

@include bp(lg) {
  ul.four-column-list {
    column-width: 225px;
  }
}

//alpha numeric filters list
.alpha-numeric-filters-title {
  color: $cutty-sark;
  font-size: 16px;
  margin: 0 0 10px 0;
  text-align: center;
}

.alpha-numeric-filters-list {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  margin: 0 auto 20px auto;
  //max-width: 800px;
  text-align: center;

  li {
    color: $silver;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: bold;
    list-style: none;
    margin: 0 5px;
    position: relative;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
    transition: all $base-duration $base-timing;

    &:first-of-type,
    &:last-of-type {
      margin: 0;
      padding: 3px 0 0 0;
      text-decoration: none;
      width: 30px;
    }

    span {
      position: relative;

      &:before {
        font-size: 24px;
        font-weight: bold;
        position: relative;
        text-decoration: none;
        top: 2px;
      }
    }

    &:hover {
      color: $sea-nymph;
    }

    &.active {
      color: $sea-nymph;
    }
  }
}

@include bp(sm) {
  .alpha-numeric-filters-title {
    text-align: left;
  }

  .alpha-numeric-filters-list {
    justify-content: space-between;
  
    li {
      margin: 0;
    }
  }
}

//dropdown list items
.dropdownlist-items,
.saved-items-container {
  background: $ada-grey-4;
  border-radius: 4px;
  font-size: 16px;
  padding: 20px;

  ul {
    background: $white;
    border: $base-border;
    list-style: none;
    margin: 0;
    max-height: 230px;
    overflow-y: scroll;
    padding: 10px;

    li {
      font-size: 16px;
      padding: 0 0 5px 0;
    }
  }
}

//variable list
#tablebuilder-record-new {
  p {
    font-size: 16px;
  }

  .number-input-max-width {
    max-width: 80px;
  }
}

.variable-headers {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 20px;

  p {
    width: 40%;

    &:last-of-type {
      width: 55%;
    }
  }
}

.variable-list {
  border: 1px solid $ada-grey-4;
  border-radius: 6px;
  max-height: 365px;
  overflow-y: scroll;
  padding: 20px;
}

.variable-list-row {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  .form-item {
    width: 40%;

    &:last-of-type {
      width: 55%;
    }
  }
}


//label list
.label-list {
  list-style: none;
  margin: 0;

  li {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;

    label,
    p {
      font-size: 14px;
      margin: 0;
    }

    label {
      font-weight: 500;
      text-align: right;
      width: 22%;
    }

    p {
      width: 75%;
    }
  }
}

@include bp(sm) {
  .label-list {
    li {
      label {
        width: 12%;
      }
  
      p {
        width: 85%;
      }
    }

    &.one-third-label {
      label {
        width: 30%;
      }

      p {width: 66%;
      }
    }
  }
}

//checkbox list with check-all hieracrchy
ul.checkbox-list {
  list-style: none;
  margin: 0;

  label {
    font-weight: 500;
    margin: 0 0 10px 0;
  }

  ul.checkbox-list {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;

    margin-left: 40px;

    label {
      font-weight: 400;
    }
  }
}

@include bp(sm) {
  ul.checkbox-list {
    ul.checkbox-list {
      li {
        width: 48%;
      }
    }
  }
}


.header-width-checkbox {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  p {
    width: 65%;
  }

  div {
    width: 30%;
  }
}

.sample-size-fixed {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  li {
    width: 40%;
    margin: 0 2% 0 0;

    // &:first-of-type {
    //   width: 40%;
    // }

    &:last-of-type {
      width: 18%;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0;
        width: 47%;

        // &:first-of-type {
        //   width: 47%;
        // }
      }
    }

    input {
      margin: 0;
    }
  }
}

///////////////////////////////////////////////
//set notifications
///////////////////////////////////////////////
ul.set-notifications {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  list-style: none;
  margin: 0 40px 0 0;

  li {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    height: 44px;
    margin: 0 5% 0 0;
    position: relative;
    text-align: right;
    width: 37.5%;

    label {
      margin: 0 5px 0 0;
      text-align: right;
      width: auto !important;
    }

    &:first-of-type {
      width: 10%;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  &.lower {
    width: 75%;

    li {
      width: 21%;

      &:first-of-type {
        width: 48%;
      }
    }
  }
}

.variable-list-bottom-container {
  width: 75%;
}