$width-inches: 10;

@mixin print-widths {
    @for $i from 1 through $width-inches {
        .print-width-#{$i} { 
            width: #{$i}#{"in"} !important;
        }
    }
}


@mixin print-widths-half-inches {
    @for $i from 1 through $width-inches {
        .print-width-halfinch-#{$i} { 
            width: #{$i}#{'.5in'} !important; 
        }
    }
}

@media print {
    @include print-widths;
    @include print-widths-half-inches;

    .half-inch {
        width: 0.5in !important;
    }

    .three-quarters-inch {
        width: 0.75in !important;
    }
    
}