// Misceallaneous Helpers
//-------------------------------------------------------------------------- */

// Center
//-------------------------------------------------------------------------- */
%center,
.u-center {
    margin-left: auto !important;
    margin-right: auto !important;
}

// Typeface Helper
//-------------------------------------------------------------------------- */
%sans-serif,
.u-sans-serif {
  font-family: $sans-serif !important;
}

%serif,
.u-serif {
  font-family: $serif !important;
}

%code,
.u-code {
  font-family: $code !important;
}

// Clearfix Helper
// ------------------------------------------------------------------------------------ */
.u-clearfix,
%clearfix {
    &:after {
        content: "" !important;
        display: table !important;
        clear: both !important;
    }
}

// Hidetext Helper
//-------------------------------------------------------------------------- */
%hidetext, .hidetext {
    text-indent: 100% !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}

// Active and Hover State Helpers
//-------------------------------------------------------------------------- */
%default-hover,
.u-default-hover {
    &:hover,
    &:focus {
        opacity: 0.5 !important;
    }
}

%default-active,
.u-default-active {
    &:active {
        opacity: 0.8 !important;
    }
}

%dim, .dim {
    @extend %default-active;
    @extend %default-hover;
}

// Site Width Helper
//-------------------------------------------------------------------------- */
%site-width,
.u-site-width {
    max-width: $site-width !important;
}

// Font Transform Helpers
//-------------------------------------------------------------------------- */
.u-lowercase {
    text-transform: lowercase !important;
}
.u-uppercase {
    text-transform: uppercase !important;
}

// Ellipses Text Helper
// ------------------------------------------------------------------------------------
.u-ell {
    overflow: hidden !important; 
    text-overflow: ellipsis !important; 
    white-space: nowrap !important;
}

// Border Removal Helper
//-------------------------------------------------------------------------- */
.u-xbrdr {
    border: none !important;
}

// Image Helpers
// ------------------------------------------------------------------------------------
.u-imax {
    max-width: 100% !important; 
    width: auto !important; 
    height: auto !important;
}
.u-i100 {
    width: 100% !important;
}

// Background Helpers
//-------------------------------------------------------------------------- */
.u-bg--cover {
    background-size: cover !important;
}
.u-bg--center {
    background-position: center !important;
}

// Vertical Align Tables
//-------------------------------------------------------------------------- */
.u-vam {
    vertical-align: middle !important;
}

.u-xts {
    text-shadow: none !important;
}

.u-disabled {
    opacity: 0.66;
    pointer-events: none;
    cursor: initial;
}

.u-center {
    margin: 0 auto;
}

.u-inline-check {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    input {
        flex: 0 1 auto;
        order: 1;
        margin: 0;
        padding: 0;
        margin-right: 1rem;
    }

    label {
        flex: 0 1 auto;
        order: 2;
        margin: 0;
        padding: 0;
    }
}