// Loop through Brand Colors and output classes for each type
//-------------------------------------------------------------------------- */

// Generate Helpers for each color
@each $id, $color in $color-map {
    .u-bg-#{$id} {
        background-color: $color !important;
    }
    .u-text-color-#{$id} {
        color: $color !important;
    }
}

// Generate helpers with hovers for each color
@each $id, $color in $color-map {
    .u-h-bg-#{$id}:hover {
        background-color: $color !important;
    }
    .u-h-text-color-#{$id}:hover {
        color: $color !important;
    }
    .u-h-border-color-#{$id}:hover {
        border-color: $color !important;
    }
}

// Generate focus helpers for each color
@each $id, $color in $color-map {
    .u-f-bg-#{$id}:focus {
        background-color: $color !important;
    }
    .u-f-text-color-#{$id}:focus {
        color: $color !important;
    }
    .u-f-border-color-#{$id}:focus {
        border-color: $color !important;
    }
}

// Generate active helpers for each color
@each $id, $color in $color-map {
    .u-active-bg-#{$id}:active {
        background-color: $color !important;
    }
    .u-active-text-color-#{$id}:active {
        color: $color !important;
    }
    .u-active-border-color-#{$id}:active {
        border-color: $color !important;
    }
}

// Color Removal Helpers
// ------------------------------------------------------------------------- */
.u-no-bg {
    background-color: transparent !important;
}