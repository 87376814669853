// Misc Sizes
//$base-border-radius: 3px;
$base-border-radius: 4px;
//$base-spacing: 1.25rem;
$base-spacing: 20px;
$small-spacing: $base-spacing / 2;
$large-spacing: $base-spacing * 2;
$xlarge-spacing: $base-spacing * 2.5;
$base-z-index: 0;

// Animations
$base-duration: 0.7s;
$base-timing: ease;

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

// Focus
$focus-outline-color: transparentize($action-color, 0.4);
$focus-outline-width: 3px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 2px;