.u-display--block {
    display: block !important;
}

.u-display--inline {
    display: inline !important;
}

.u-display--inline-block {
    display: inline-block !important;
}

.u-display--none {
    display: none !important;
}

.u-display--flex {
    display: flex !important;
}

.u-full {
    display: block !important;
    width: 100% !important;
}

.u-w100 {
    width: 100% !important;
}

@include bp(md) {
    .u-w100-desk {
        width: 100% !important;
    }
}

//mobile - desk view toogles
.u-display--mobile {
    display: inherit !important;
}

.u-display--desk {
    display: none !important;
}

@include bp(sm) {
    .u-display--mobile {
        display: none !important;
    }

    .u-display--tablet {
        display: inherit !important;
    }
}

@include bp(md) {
    .u-display--tablet {
        display: none !important;
    }
    
    .u-display--desk {
        display: inherit !important;
    }
}


@each $key, $breakpoint in $breakpoints {
    .#{$key}-u-display--block {
      @include bp($breakpoint) {
          display: block !important;
      }
  }

  .#{$key}-u-display--inline {
      @include bp($breakpoint) {
          display: inline !important;
      }
  }

  .#{$key}-u-display--inline-block {
      @include bp($breakpoint) {
          display: inline-block !important;
      }
  }

  .#{$key}-u-display--none {
      @include bp($breakpoint) {
          display: none !important;
      }
  }

  .#{$key}-u-display--flex {
      @include bp($breakpoint) {
          display: flex !important;
      }
  }

  .#{$key}-u-w100 {
        @include bp($breakpoint) {
            width: 100% !important;
        }
    }

    .#{$key}-u-wauto {
        @include bp($breakpoint) {
            width: auto !important;
        }
    }
}
