// Line Height Helpers
// ------------------------------------------------------------------------------------ */
.u-line-height--1 {
    line-height: 1 !important;
}

.u-line-height--1-1 {
    line-height: 1.1 !important;
}

.u-line-height--1-2 {
    line-height: 1.2 !important;
}

.u-line-height--1-3 {
    line-height: 1.3 !important;
}

.u-line-height--1-4 {
    line-height: 1.4 !important;
}

.u-line-height--1-5 {
    line-height: 1.5 !important;
}

.u-line-height--1-6 {
    line-height: 1.6 !important;
}

.u-line-height--1-7 {
    line-height: 1.7 !important;
}

.u-line-height--1-8 {
    line-height: 1.8 !important;
}

.u-line-height--1-9 {
    line-height: 1.9 !important;
}

.u-line-height--2 {
    line-height: 2 !important;
}


@each $key, $breakpoint in $breakpoints {

    .#{$key}-u-line-height--1 {
        @include bp($breakpoint) {
            line-height: 1 !important;
        }
    }

    .#{$key}-u-line-height--1-1 {
        @include bp($breakpoint) {
            line-height: 1.1 !important;
        }
    }

    .#{$key}-u-line-height--1-2 {
        @include bp($breakpoint) {
            line-height: 1.2 !important;
        }
    }

    .#{$key}-u-line-height--1-3 {
        @include bp($breakpoint) {
            line-height: 1.3 !important;
        }
    }

    .#{$key}-u-line-height--1-4 {
        @include bp($breakpoint) {
            line-height: 1.4 !important;
        }
    }

    .#{$key}-u-line-height--1-5 {
        @include bp($breakpoint) {
            line-height: 1.5 !important;
        }
    }

    .#{$key}-u-line-height--1-6 {
        @include bp($breakpoint) {
            line-height: 1.6 !important;
        }
    }

    .#{$key}-u-line-height--1-7 {
        @include bp($breakpoint) {
            line-height: 1.7 !important;
        }
    }

    .#{$key}-u-line-height--1-8 {
        @include bp($breakpoint) {
            line-height: 1.8 !important;
        }
    }

    .#{$key}-u-line-height--1-9 {
        @include bp($breakpoint) {
            line-height: 1.9 !important;
        }
    }

    .#{$key}-u-line-height--2 {
        @include bp($breakpoint) {
            line-height: 2 !important;
        }
    }

}