figure {
  margin: 0;
}

img,
picture {
  display: block;
  height: auto;
  margin: 0;
  max-width: 100%;
}

img.uploaded-image {
  height: auto;
  max-width: 200px;
}

iframe {
  border: none;
  height: auto;
  min-height: 300px;
  width: 100%;
}

.blueberry-report {
  iframe {
    min-height: 0;
  }
}


@include bp(md) {
  iframe {
    min-height: 400px;
  }
}