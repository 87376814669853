$icon-size-map: (
    "sm": 20px,
    "md": 24px,
    "lg": 28px,
);

// icon sizes
@each $name, $value in $icon-size-map {
  .u-icon-size--#{$name} {
      height: $value;
      font-size: $value;
      width: $value;
      
      &:before {
        width: $value;
    }
  }
}