// Flexbox Helpers
// ------------------------------------------------------------------------------------ */

.u-flex {
    display: flex;
    flex-flow: row wrap;
}

@include bp(md) {
    .u-flex-desk {
        display: flex;
        flex-flow: row wrap;
    }
}

.u-iflex {
    display: inline-flex !important;
}

.u-flex-grow {
    flex-grow: 1 !important;
}

.u-flex-all-flexstart {
    display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: flex-start !important;
}

.u-flex-all-center {
    display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center !important;
}

.u-flex-all-space-between {
    display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: center;
	justify-content: space-between !important;
}

.u-flex-all-end {
    display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	align-items: center;
	justify-content: flex-end !important;
}


// Flex Basis Helpers
//-------------------------------------------------------------------------- */

.u-flex-full {
    flex-basis: 100% !important;
}


// Flex Direction Helpers
//-------------------------------------------------------------------------- */

.u-flex-row {
    flex-direction: row !important;
}

.u-flex-row--reverse {
    flex-direction: row-reverse !important;
}

@include bp(sm) {
    .u-flex-row--reverse-sm {
        flex-direction: row-reverse !important;
    }
}

@include bp(md) {
    .u-flex-row--reverse-md {
        flex-direction: row-reverse !important;
    }
}

.u-flex-column {
    flex-direction: column !important;
}

.u-flex-column--reverse {
    flex-direction: column-reverse !important;
}


// Flex Wrap Helpers
//-------------------------------------------------------------------------- */

.u-flex-wrap--nowrap {
    flex-wrap: nowrap !important;
}

.u-flex-wrap {
    flex-wrap: wrap !important;
}

.u-flex-wrap--reverse {
    flex-wrap: wrap-reverse !important;
}


// Justification Helpers
//-------------------------------------------------------------------------- */

.u-justify-content--center {
    justify-content: center !important;
}

.u-justify-content--between {
    justify-content: space-between !important;
}

.u-justify-content--around {
    justify-content: space-around !important;
}

.u-justify-content--start {
    justify-content: flex-start !important;
}

.u-justify-content--end {
    justify-content: flex-end !important;
}


// Align Items Helpers
//-------------------------------------------------------------------------- */

.u-align-items--center {
    align-items: center !important;
}

.u-align-items--start {
    align-items: flex-start !important;
}

.u-align-items--end {
    align-items: flex-end !important;
}

.u-align-items--stretch {
    align-items: stretch !important;
}

.u-align-items--baseline {
    align-items: baseline !important;
}


// Align Content Helpers
//-------------------------------------------------------------------------- */

.u-align-content--center {
    align-content: center !important;
}

.u-align-content--start {
    align-content: flex-start !important;
}

.u-align-content--end {
    align-content: flex-end !important;
}

.u-align-content--stretch {
    align-content: stretch !important;
}

.u-align-content--baseline {
    align-content: baseline !important;
}

// Align Self Helpers
//-------------------------------------------------------------------------- */

.u-align-self--start {
    align-self: flex-start;
}

.u-align-self--center {
    align-self: center;
}

.u-align-self--end {
    align-self: flex-end;
}

.u-align-self--stretch {
    align-self: stretch;
}



@each $key, $breakpoint in $breakpoints {
    .#{$key}-u-flex {
        @include bp($breakpoint) {
            display: flex !important;
        }
    }

    .#{$key}-u-inline-flex {
        @include bp($breakpoint) {
            display: inline-flex !important;
        }
    }

    .#{$key}-u-flex-full {
        @include bp($breakpoint) {
            flex-basis: 100% !important;
        }
    }

    // Flex Direction
    //-------------------------------------------------------------------------- */

    .#{$key}-u-flex-row {
        @include bp($breakpoint) {
            flex-direction: row !important;
        }
    }

    .#{$key}-u-flex-row--reverse {
        @include bp($breakpoint) {
            flex-direction: row-reverse !important;
        }
    }

    .#{$key}-u-flex-column {
        @include bp($breakpoint) {
            flex-direction: column !important;
        }
    }

    .#{$key}-u-flex-column--reverse {
        @include bp($breakpoint) {
            flex-direction: column-reverse !important;
        }
    }

    .#{$key}-u-flex-wrap--nowrap {
        @include bp($breakpoint) {
            flex-wrap: nowrap !important;
        }
    }

    .#{$key}-u-flex-wrap {
        @include bp($breakpoint) {
            flex-wrap: wrap !important;
        }
    }

    .#{$key}-u-flex-wrap--reverse {
        @include bp($breakpoint) {
            flex-wrap: wrap-reverse !important;
        }
    }

    // Justify Content
    //-------------------------------------------------------------------------- */

    .#{$key}-u-justify-content--center {
        @include bp($breakpoint) {
            justify-content: center !important;
        }
    }

    .#{$key}-u-justify-content--between {
        @include bp($breakpoint) {
            justify-content: space-between !important;
        }
    }

    .#{$key}-u-justify-content--around {
        @include bp($breakpoint) {
            justify-content: space-around !important;
        }
    }

    .#{$key}-u-justify-content--start {
        @include bp($breakpoint) {
            justify-content: flex-start !important;
        }
    }

    .#{$key}-u-justify-content--end {
        @include bp($breakpoint) {
            justify-content: flex-end !important;
        }
    }

    // Align Items
    //-------------------------------------------------------------------------- */

    .#{$key}-u-align-items--center {
        @include bp($breakpoint) {
            align-items: center !important;
        }
    }

    .#{$key}-u-align-items--start {
        @include bp($breakpoint) {
            align-items: flex-start !important;
        }
    }

    .#{$key}-u-align-items--end {
        @include bp($breakpoint) {
            align-items: flex-end !important;
        }
    }

    .#{$key}-u-align-items--stretch {
        @include bp($breakpoint) {
            align-items: stretch !important;
        }
    }

    .#{$key}-u-align-items--baseline {
        @include bp($breakpoint) {
            align-items: baseline !important;
        }
    }

    // Align Content
    //-------------------------------------------------------------------------- */

    .#{$key}-u-align-content--center {
        @include bp($breakpoint) {
            align-content: center !important;
        }
    }

    .#{$key}-u-align-content--start {
        @include bp($breakpoint) {
            align-content: flex-start !important;
        }
    }

    .#{$key}-u-align-content--end {
        @include bp($breakpoint) {
            align-content: flex-end !important;
        }
    }

    .#{$key}-u-align-content--stretch {
        @include bp($breakpoint) {
            align-content: stretch !important;
        }
    }

    .#{$key}-u-align-content--baseline {
        @include bp($breakpoint) {
            align-content: baseline !important;
        }
    }
}