@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;0,900;1,400;1,600;1,700;1,900&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $base-font-family;
    color: $ship-gray;
    font-weight: 700;
    line-height: $heading-line-height;
    margin: 0 0 20px 0;
    //width: 100%;
}

//h1 styles
h1 {
    font-size: 22px;
    margin: 0 0 20px 0;  
    padding: 0 0 10px 0;    
    position: relative;

    &:after {
        background: $hub;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        width: 100%;
    }

    span {
        font-weight: 400;

        &.small {
            font-size: 14px;
            font-style: italic;
        }

        &.small.u-float--right {
            margin-top: 6px;
        }
    }

    &.map-views-header,
    &.no-bottom-border,
    &.no-border {
        margin: 0;
        padding: 0 0 5px 0;

        &:after {
            display: none;
        }
    }

    &.big {
        border-bottom: 2px solid $hub;
        display: inline;
        font-size: 23px;
        margin: 0 auto 20px auto;
        text-align: center;
        width: auto;

        &:after {
            display: none;
        }
    }

    &.background-marigold,
    &.background-pistachio,
    &.background-hub,
    &.background-concrete,
    &.background-hub-lightest {
        padding: 5px 10px;
        color: $white;

        &:after {
            display: none;
        }

        a {
            color: $white;
            text-decoration: underline;
        }
    }

    &.background-marigold {
        background: $marigold;
    }

    &.background-pistachio {
        background: $pistachio;
    }

    &.background-hub {
        background: $hub;
    }

    &.background-hub-lightest {
        background: $hub-lightest;
    }

    &.background-concrete {
        background: $concrete;
        color: $almost-black;
        font-size: 18px;
        font-weight: normal;
    }

    &.toggle-button {
        &:after {
            display: none;
        }
    }
}

.title-edit-delete-container {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 5px 0;

    h1 {
        margin: 0 10px 0 0;
        padding: 0;
        width: auto;

        &:after {
            display: none;
        }
    }

    a.btn {
        background: $marigold;
        color: $white;
        margin: 0 10px 0 0;
        padding: 5px 7px;

        span {
            &:before {
                color: $white;
            }
        }

        &:last-of-type {
            margin: 0;
        }
    }
}

@include bp(md) {
    h1 {
        font-size: 24px;
    }
}

//h2 styles
h2 {
    font-size: 20px;
    font-weight: 600;

    &.instructions {
        border-bottom: 2px solid $hub;
        border-top: 2px solid $hub;
        margin: 0 0 40px 0;
        padding: 15px 0 20px 65px;
        position: relative;

        &:before {
            //background: $hub;
            background: $marigold;
            border-radius: 50%;
            color: $white;
            content: '!';
            display: block;
            font-size: 32px;
            height: 45px;
            left: 0;
            margin-top: -22.5px;
            padding: 2px 0 0 0;
            position: absolute;
            text-align: center;
            top: 50%;
            width: 45px;
        }
    }

    &.instructions.no-border {
        border: none;
    }
}

@include bp(md) {
    h2 {
        font-size: 22px;
    }
}

#fb-render {
    // h2,
    // h3 {
    //     font-weight: 600;
    //     margin: 0 0 bsu(1) 0;
    // }

    // h2 {
    //     font-size: 18px;
    // }

    // h3 {
    //     font-size: 16px;
    // }
}

[data-view="password-recovery"],
[data-view="login"],
[data-view="create-user"],
[data-view="create-company"],
[data-view="thank-you"] {
    h1 {
        margin: 0 0 20px 0;
    }

    h2 {
        padding: 0 0 10px 0;
        position: relative;

        &:after {
            background: $hub;
            bottom: 0;
            content: '';
            display: block;
            height: 2px;
            position: absolute;
            width: 100%;
        }
    }
}

//h3 styles
h3 {
    font-size: 18px;
    font-weight: 700;
    //margin: 0 0 20px 0;
    //text-align: left;

    &.bg-hub,
    &.bg-pistachio,
    &.bg-marigold,
    &.bg-iron {
        background: $hub;
        color: $white;
        //font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        margin: 0 0 10px 0;
        padding: 5px 20px;
    }

    &.bg-marigold {
        background: $marigold;
    }

    &.bg-pistachio {
        background: $pistachio;
    }

    &.bg-hub-light {
        background: $hub-light;
    }

    &.bg-hub-lightest {
        background: $hub-lightest;
        color: $ship-gray;
    }

    &.bg-iron {
        background: $iron;
        color: $ship-gray;
    }
}

@include bp(md) {
    h3 {
        font-size: 20px;
    }
}

//h4 styles
h4 {
    font-size: 16px;
    font-weight: 600;

    // &.weight-500 {
    //     font-weight: 500;
    // }
}

@include bp(md) {
    h4 {
        font-size: 18px;
    }
}

//h5 styles
h5 {
    font-size: 16px;
    font-weight: 400;
}

@include bp(md) {
    h5 {
        font-size: 18px;
    }
}

//paragraph styles
p {
    font-size: 16px;
    margin: 0 0 20px 0;
    width: auto;

    span {
        font-weight: 600;

        &.underline {
            font-weight: normal;
        }
    }

    &.disclaimer {
        color: $red-orange;
        font-size: 12px;
    }
}

//disclaimers, captions, and required fields
div {
    &.disclaimer {
        color: $red-orange !important;
        font-size: 12px !important;
        margin: 0 0 10px 0;
        text-align: left !important;
    }
}

//link styles
.ui-widget-content {
    a {
        color: $link-green
    }
}

a {
    color: $link-green;
    cursor: pointer;
    //added in accordance to US 8587, even though not noted
    font-weight: 600;
    outline: none;
    text-decoration: none;
    transition: all $base-duration $base-timing;

    svg {
		color: $hub;
		fill: currentColor;
		transition: all $base-duration $base-timing;
	}

	h2 {
		color: $ship-gray;
		transition: all $base-duration $base-timing;
    }
    
    &:hover,
    &:focus {
        color: $pistachio;

        svg {
			color: $ship-gray;
		}

		h2 {
			color: $hub;
		}
    }

    &.dark-pistachio {
        color: $dark-pistachio;
    }

    //not active
    &.not-active {
        color: $iron;
        pointer-events: none;

        h1,
        h2,
        h3,
        h4 {
            color: $iron;
        }

        svg {
            color: $iron;
            fill: $iron;
        }

        &:hover {
            color: $iron;

            h1,
            h2, 
            h3,
            h4 {
                color: $iron;
            }

            svg {
                color: $iron;
                fill: $iron;
            }
        }
    }
}

.pre {
    font-family: $code;
    overflow-x: auto;
    overflow-y: hidden;
    overflow:   scroll;
}

pre {
    //font-family: $heading-font-family;
    font-size: 14px;
    white-space: pre-wrap;
    word-break: break-word;
}

blockquote {
    margin: 0 0 bsu(1) 0;
    overflow: hidden;
    padding: bsu(1.5);
    position: relative;
    text-align: left;
}

hr {
    border-bottom: $base-border;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin: $base-spacing 0;
}

.column-header {
    font-weight: 700;
}

.orange,
input.orange {
    color: $orange;

    &::placeholder {
        color: $orange;
    }
}

.marigold {
    color: $marigold;
}

.red,
input.red,
.rejected,
.easy-autocomplete input.red {
    color: $red-orange !important;

    &::placeholder {
        color: $red-orange !important;
    }
}

p.table-inline-validation-error {
    color: $red-orange !important;
    display: none;
    margin: 0;

    &.active {
        display: block;
    }
}

.green,
input.green {
    color: $link-green;

    &::placeholder {
        color: $link-green;
    }
}

.ck-content span {
    color: $red-orange;
    display: inline;
}

.triangle {
    color: $white;
    display: block;
    font-size: 18px;
    font-weight: 600;
    padding: 0;
    position: relative;
    text-align: center;
    width: 24px;
    z-index: 1;

    &:after {
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 24px solid $marigold;
        content: "";
        height: 0;
        left: 0;
        position: absolute;
        top: -1px;
        width: 0;
        z-index: -1;
    }
}

//custom weights
.bold {
    font-weight: 700;
}

.medium-weight {
    font-weight: 600 !important;
}

.font-weight-normal,
.normal-weight {
    font-weight: 400;
}

//specific font sizes
.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-16 {
    font-size: 16px;
}

//text decorations
.underline {
    text-decoration: underline;
}

.highlight {
    background: $yellow;
    padding: bsu(0.25);
}

.strikethrough {
    text-decoration: line-through;
}

.italic {
    font-style: italic;
}

.normal {
    font-style: normal;
}

.highlight,
tr td.highlight,
tr td:nth-of-type(2n+2).highlight {
    background: $starship;
}

tr.highlight {
    td {
        background: $gallery !important;

        &:first-of-type {
            border-radius: 6px 6px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 6px 6px;
        }
    }
}

@include bp(md) {
    tr.highlight {
        td {    
            &:first-of-type {
                border-radius: 6px 0 0 6px;
            }
    
            &:last-of-type {
                border-radius: 0 6px 6px 0;
            }
        }
    }
}

//list counter
ol.big-numbers {
    list-style: none;
    counter-reset: my-awesome-counter;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;

    li {
        counter-increment: my-awesome-counter;
        margin: 0 0 10px 0;
        padding: 4px 0 0 40px;
        position: relative;
        text-align: left;
        width: 100%;

    
        &:before {
            background: $pistachio;
            border-radius: 100%;
            color: $white;
            content: counter(my-awesome-counter);
            display: inline-block;
            font-size: 24px;
            font-weight: bold;
            height: 30px;
            left: 0;
            line-height: 1;
            margin-right: 5px;
            padding: 2px 0 0 0;
            position: absolute;
            text-align: center;
            top: 0;
            width: 30px;
        }
    }

    &.striped {
        li {
            background: $concrete;
            padding: 20px 20px 0 10px;

            &:nth-of-type(2n+2) {
                background: transparent;
            }

            &:before {
                left: 13px;
                top: 13px;
            }

            h4,
            .form-item {
                padding-left: 40px;
            }
        }
    }
}

//rendered html containers
.rendered-html-container {
    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
    }
    
    h1 {
        margin: 0;
        padding: 0 0 bsu(0.25) 0;

        &:after {
            display: none;
        }
    }

    p {
        span {
            font-weight: normal;
        }
    }
}

//responsive text columns
.responsive-text-columns {
    columns: 2 200px;
    column-gap: 2em;

    &.three-text-columns {
        columns: 3 200px;
    }
}

//white-space: pre-wrap;
.pre-wrap {
    white-space: pre-wrap;
}

//translation overrides
font {
    vertical-align: top !important;
}

//sections with subheads
h1.with-subhead {
    margin: 0;

    &:after {
        display: none;
    }
}

section.with-subhead {
    margin: 0 0 bsu(2) 0;

    h2 {
        background: $hub;
        color: $white;
        padding: bsu(0.5);
        width: 100%;
    }

    .form-flex {
        margin: 0;
    }
}

///////////////////////////////////////////////
//new design
///////////////////////////////////////////////
body.new-design {
    h1 {
        &.container-header {
            background: transparent;
            border-bottom: 1px solid $gallery;
            font-size: 16px;
            font-weight: normal;
            margin: 0;
            padding: 25px;
            text-align: left;
        }

        &.content-container-header {
            background: $sea-nymph;
            border-radius: 6px;
            color: $white;
            font-size: 22px;
            margin: 0;
            padding: 15px;
        }

        &.rio-grande-border-bottom {
            margin: 0 0 bsu(1) 0;  
            padding: 0 0 bsu(0.5) 0;

            &:after {
                background: $rio-grande;
                bottom: 0;
                content: '';
                display: block;
                height: 2px;
                position: absolute;
                width: 100%;
            }
        }

        &.border-bottom {
            margin: 0 0 bsu(1) 0;  
            padding: 0 0 bsu(0.5) 0;

            &:after {
                bottom: 0;
                content: '';
                display: block;
                height: 2px;
                position: absolute;
                width: 100%;
            }
        }

        &.spring-leaves-border-bottom {
            &:after {
                background: $spring-leaves;
            }
        }

        &.killarney-border-bottom {
            &:after {
                background: $killarney;
            }
        }
    } 

    h1,
    h2,
    h3,
    h4,
    .ui-widget,
    label,
    input, 
    select, 
    textarea {
        color: $mine-shaft;
    }

    h1 {
        font-weight: bold;

        &:after {
            display: none;
        }

        a {
            color: $sea-nymph;
        }
    }

    a {
        color: $plantation;
    }

    //color classes
    .cutty-sark {
        color: $cutty-sark;
    }

    //wave backgrounds
    .bg-wave {
        h1,
        h3 {
            color: $white;
        }
    }

    //reds
    .red,
    input.red,
    .rejected,
    .easy-autocomplete input.red {
        color: $not-submitted !important;

        &::placeholder {
            color: $not-submitted !important;
        }
    }

    p.table-inline-validation-error {
        color: $not-submitted !important;
    }
}

///////////////////////////////////////////////
//page headers
///////////////////////////////////////////////

 .page-header {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    background: $pistachio;
    border-radius: 6px;
    height: 55px;
    margin: 0 0 30px 0;
    padding: 0 15px;

    h1 {
        color: $white;
        font-size: 22px;
        margin: 0;
        padding: 0;

        &:after {
            display: none;
        }
    }
}

@include bp(md) {
    .page-header {
        height: 88px;
        margin: 0 0 35px 0;
        padding: 0 25px;

        h1 {
            font-size: 30px;
        }
    }
}

///////////////////////////////////////////////
//header container variations
///////////////////////////////////////////////

.border-center-header-container {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;

    h2 {
        margin: 0 0 20px 0;  
        padding: 0 0 10px 0;    
        position: relative;
        width: 100%;

        &:after {
            background: $hub;
            bottom: 0;
            content: '';
            display: block;
            height: 2px;
            position: absolute;
            width: 100%;
        }
    }
}

@include bp(md) {
    .border-center-header-container {
        border-bottom: 2px solid $hub;

        h2 {
            margin: 0;
            padding: 0;
            width: auto;

            &:after {
                display: none;
            }
        }
    }
}