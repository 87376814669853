//site-grids
.site-grid {
    list-style: none;
    margin: 0;

    li {
        background: $concrete;
        margin: 0 0 bsu(1) 0;
        position: relative;

        h3 {
            font-size: 14px;
            margin: 0;
            padding: bsu(0.5) bsu(0.75);
        }

        .thumb-img-container {
            height: 160px;
            width: 100%;
        }
    }

    h3.toggle-header {
        padding: bsu(0.5) 35px bsu(0.5) 25px;
        position: relative;
    }

    .toggle-button {
        left: 5px;
        position: absolute;
        text-align: left;
        top: 7px;
    }

    .toggle-content {
        background: $iron;
        box-shadow: inset 0 0 10px rgba($gray-chateau, 0.5);
        padding: bsu(1);

        p {
            margin: 0;
            text-align: left;

            span {
                font-weight: 600;
            }
        }
    }

    &.info-listing {
        li {
            background: $hub;
        }

        a {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            flex-flow: row wrap;
            vertical-align: top;
        }

        div {
            padding: bsu(0.75);
            width: 100%;

            ul {
                list-style: none;
                margin: 0;

                li {
                    color: $white;
                    list-style: none;
                    margin: 0;
                    width: 100%;
                }
            }
        }

        h3 {
            background: $iron;
            padding: bsu(0.5) bsu(0.75);
            width: 100%;
        }
    }

    &.season {
        h2 {
            background: $pistachio;
            color: $white;
            font-weight: bold;
            margin: 0;
            padding: 5px 10px;
        }

        a {
            &:before {
                display: none;
            }

            &:hover {
                &:before {
                    display: none;
                }
            }
        }

        section {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: flex-s;
    
            padding: 10px;

            label {
                width: 50%;

                &:before {
                    display: none;
                }

                &:hover {
                    &::before {
                        display: none;
                    }
                }
            }

            p {
                margin: 0 0 5px 0;
                width: 50%;
            }

            a.btn {
                margin: 5px 0 0 50%;
                width: 50%;
            }
        }
    }
}

@include bp(sm) {
    .site-grid {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;
        align-items: flex-start;

        li {
            margin: 0 4% bsu(1) 0;
            width: 48%;

            &:nth-child(2n+2) {
                margin: 0 0 bsu(1) 0;
            }
        }
    }
}

@include bp(md) {
    .site-grid {
        li {
            margin: 0 2% bsu(1) 0;
            width: 32%;

            &:nth-child(2n+2) {
                margin: 0 2% bsu(1) 0;
            }

            &:nth-child(3n+3) {
                margin: 0 0 bsu(1) 0;
            }
        }
    }
}

@include bp(lg) {
    .site-grid {
        li {
            width: 23.5%;

            &:nth-child(3n+3) {
                margin: 0 2% bsu(1) 0;
            }

            &:nth-child(4n+4) {
                margin: 0 0 bsu(1) 0;
            }
        }
    }
}

//flex-grid
.flex-grid.mobile-flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;

    align-content: flex-end;

    .grid-item {
        display: inline-block;
        margin: 0 4% 0 0;
        width: 48%;

        &:nth-of-type(2n+2) {
            margin: 0 0 0 0;
        }

        &.full-width {
            margin: 0;
            width: 100%;
        }

        .button,
        .btn {
            width: 100%;
        }
    }
}

.modal {
    .flex-grid {
        margin: 0 auto;
        width: 80%;

        .grid-item {
            margin-bottom: bsu(1.5);

            svg {
                margin: 0 auto bsu(0.25) auto;
                max-height: 80px;
                max-width: 80px;
                width: auto;
            }
        }
    }
}

@include bp(sm) {
    .flex-grid {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;

        align-content: flex-end;

        .grid-item {
            display: inline-block;
            margin: 0 4% 0 0;
            width: 48%;

            &:nth-of-type(2n+2) {
                margin: 0 0 0 0;
            }

            &.full-width {
                margin: 0;
                width: 100%;
            }

            .button,
            .btn {
                width: 100%;
            }
        }
    }
}

@include bp(md) {
    .modal {
        .flex-grid {
            width: 90%;
        }
    }
}

@media print {
    .flex-grid.map-views {
        display: block;
        overflow: hidden;

        .grid-item.u-margin-bottom-4 {
            float: left;
            margin-bottom: 4mm;
        }
    }

    table.non-responsive.map-views {
        tr {
            th, td {
                font-size: 10px !important;
                padding: 1mm 4mm 1mm 1mm !important;
            }

            td {
                background: #f3f3f3 !important;
            }

            &:nth-of-type(2n+2) {
                background: transparent !important;
            }
        }

    }
}

//tile grid 
ul.tile-grid {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;

    list-style: none;
    margin: 0;

    li {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;

        border-radius: 4px;
        box-shadow: 0 0 8px 0 rgba(0,0,0,0.25);
        margin: 0 0 15px 0;
        padding: 15px;
        position: relative;
        width: 100%;

        a {
            padding: 15px;
            width: 100%;

            h2 {
                color: $link-green;
                font-weight: 600;
                margin: 0 0 10px 0 !important;
                padding: 0 30px 0 0 !important;
                width: 100%;
            }

            label,
            p {
                line-height: 1;
                width: 100%;
            }

            label {
                color: $grey;
                font-size: 12px;
                font-weight: 400;
            }

            p {
                color: $mine-shaft;
                font-size: 14px;
                margin: 0;

                &.crop-name {
                    font-size: 16px;
                }
            }

            &:hover {
                label {
                    color: $grey;
                }
    
                p {
                    color: $mine-shaft;
                }
            }
        }

        .ellipsis-container {
            border-radius: 0;
            box-shadow: none;
            position: absolute;
            right: 15px;
            top: 18px;

            .ellipsis-option-list,
            .ellipsis-option-list-custom {
                left: initial;
                right: 0;
                top: 20px;

                li {
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    margin: 0;
                    padding: 0;
                    width: 100%;

                    a {
                        padding: 0;
                    }

                    &:hover {
                        border: none;
                    }
                }
            }
            
            a.u-icon-ellipsis {
                &:before {
                    left: initial;
                    right: 0;
                }
            }
        }
    }

    .season-specs {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        list-style: none;
        margin: 0;

        div {
            border-radius: 0;
            box-shadow: none;
            margin: 0 0 10px 0;
            padding: 00 15px;
            position: relative;
            width: 100%;
        }
    }

    &.view-list {
        .season-specs {
            div {
                margin: 0 4% 10px 0;
                padding: 0;
                width: 48%;
    
                &:nth-of-type(2n+2) {
                    margin: 0 0 10px 0;
                }

                &.ellipsis-container {
                    margin: 0;
                    width: 24px;

                    li {
                        width: 100%;
                    }
                }
            }
        }
    }

    &.tile-link {
        li {
            border: 2px solid $white;
            padding: 0;
            position: relative;
            transition: all $base-duration $base-timing;

            &:hover {
                border: 2px solid $pistachio;
            }

            h2 {
                display: block;
                margin: 0;
                padding: 0;
                width: 100%;
               
                a {
                    color: $link-green;
                    display: block;
                    padding: 15px;

                    &:hover {
                        color: $link-green;
                    }
                }
            }

            ul {
                right: 10px;
                position: absolute;
                width: 130px;

                li.ellipsis-container {
                    border: none;
                    height: 44px;
                    margin: 0;
                    right: 0;
                    top: 0;
                    width: 100px;

                    &:hover {
                        border: none;
                    }

                    a.u-icon-ellipsis {
                        display: block;
                        height: 44px;
                        margin: 0;
                        width: 100px;

                        &:before {
                            left: initial;
                            margin-top: -10px;
                            right: 0;
                            top: 50%;
                        }
                    }

                    ul.ellipsis-option-list {
                        top: 80%;

                        li {
                            border: none;

                            &:hover {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include bp(sm) {
    ul.tile-grid {
        li {
            margin: 0 2% 15px 0;
            width: 49%;

            &:nth-of-type(2n+2) {
                margin: 0 0 15px 0;
            }

            .season-specs {
                div {
                    width: 100%;
                }
            }
        }

        &.view-list {
            li {
                margin: 0 0 15px 0;
                width: 100%;
            }

            .season-specs {
                //align-items: center;
                width: 100%;

                div {
                    margin: 0 2% 10px 0;
                    width: 18.4%;
        
                    &:nth-of-type(2n+2) {
                        margin: 0 2% 10px 0;
                    }

                    &:nth-of-type(4n+4) {
                        margin: 0 0 10px 0;
                    }

                    &.ellipsis-container {
                        position: relative;
                        right: inherit;
                        text-align: right;
                        top: inherit;
                        width: 18.4%;

                        a.u-icon-ellipsis {
                            margin: 0 0 0 auto;
                            width: 24px;
                        }
                    }
                }
            }
        }
    }
}

@include bp(md) {
    ul.tile-grid {
        li {
            margin: 0 2% 15px 0;
            width: 23.5%;

            &:nth-of-type(2n+2) {
                margin: 0 2% 15px 0;
            }

            &:nth-of-type(4n+4) {
                margin: 0 0 15px 0;
            }
        }

        &.view-list {
            li {
                align-items: center;

                &:nth-of-type(2n+2) {
                    margin: 0 0 15px 0;
                }

                a {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    flex-flow: row wrap;
                    align-items: center;
                    align-content: flex-start;
                    justify-content: space-between;

                    h2 {
                        margin: 0 2% 0 0 !important;
                        width: 18.4%;
                    }
                }

                .ellipsis-container {
                   top: 25px;
                }
            }

            .season-specs {
                width: 79.6%;

                div {
                    margin-bottom: 0;
                }
            }
        }

        &.tile-link {
            li {
                align-items: flex-start;
            }
        }
    }
}

@include bp(lg) {
    ul.tile-grid {
        li {
            margin: 0 2% 15px 0;
            width: 18.4%;

            &:nth-of-type(2n+2) {
                margin: 0 2% 15px 0;
            }

            &:nth-of-type(4n+4) {
                margin: 0 2% 15px 0;
            }

            &:nth-of-type(5n+5) {
                margin: 0 0 15px 0;
            }
        }

        &.view-list {
            li {
                margin: 0 0 15px 0;
                width: 100%;
            }

            .season-specs {
                align-items: center;

                div {
                    margin: 0 2% 10px 0;
                    width: 18.4%;
        
                    &:nth-of-type(2n+2) {
                        margin: 0 2% 10px 0;
                    }

                    &:nth-of-type(4n+4) {
                        margin: 0 0 10px 0;
                    }

                    &.ellipsis-container {
                        position: relative;
                        right: inherit;
                        top: inherit;
                    }
                }
            }
        }
    }
}

//flexbox grid
.flexbox-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;

    width: 100%;

    .grid-item {
        margin: 0 0 16px 0;
        position: relative;
        width: 100%;

        .k-multiselect-wrap,
        input,
        select,
        textarea {
            margin-bottom: 0;
        }
    }

    &.two-column-mobile {
        .grid-item {
            width: 49%;

            .grid-item {
                width: 100%;
            }
        }
    }
}

@include bp(md) {
    .flexbox-grid {
        .grid-item {
            #{$all-buttons},
            .button,
            .btn,
            .ui-widget input.btn,
            .k-button,
            .k-filter-menu .k-button,
            .k-edit-form-container .k-button, 
            .k-edit-form-container .k-primary, 
            .k-popup .k-button, 
            .k-popup .k-primary,
            .ui-widget-content a.btn {
                margin-top: 33px;
            }

            &.full-width {
                width: 100% !important;
            }

            &.one-half {
                width: 49% !important;
            }

            &.two-thirds {
                width: 66% !important;
            }

            &.three-quarters {
                width: 74.5% !important;
            }

            .flexbox-grid {
                &.two-column {
                    .grid-item {
                        width: 49%;
                    }
                }
        
                &.three-column {
                    .grid-item {
                        width: 32%;
                    }
                }
        
                &.four-column {
                    .grid-item {
                        width: 23.5%;
                    }
                }
            }
        }

        &.two-column {
            .grid-item {
                width: 49%;
            }
        }

        &.three-column {
            .grid-item {
                width: 32%;
            }
        }

        &.four-column {
            .grid-item {
                width: 23.5%;
            }
        }

        &.five-column {
            .grid-item {
                width: 18.4%;
            }
        }

        &.six-column {
            .grid-item {
                width: 15%;
            }
        }

        &.seven-column {
            .grid-item {
                width: 12.57%;
            }
        }

        &.eight-column {
            .grid-item {
                width: 10.75%;
            }
        }

        &.nine-column {
            .grid-item {
                width: 9.33%;
            }
        }

        &.ten-column {
            .grid-item {
                width: 8.2%;
            }
        }

        &.eleven-column {
            .grid-item {
                width: 7.27%;
            }
        }

        &.twelve-column {
            .grid-item {
                width: 6.5%;
            }
        }
    }
}

@media print {
    .flexbox-grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: space-between;
    
        width: 100%;
    
        .grid-item {
            margin: 0 0 16px 0;
            position: relative;
            width: 100%;
            
            .k-multiselect-wrap,
            input,
            select,
            textarea {
                margin-bottom: 0;
            }
        }
    
        &.two-column-mobile {
            .grid-item {
                width: 49%;
            }
        }

        .grid-item {
            #{$all-buttons},
            .button,
            .btn,
            .ui-widget input.btn,
            .k-button,
            .k-filter-menu .k-button,
            .k-edit-form-container .k-button, 
            .k-edit-form-container .k-primary, 
            .k-popup .k-button, 
            .k-popup .k-primary,
            .ui-widget-content a.btn {
                margin-top: 26px;
            }

            &.full-width {
                width: 100% !important;
            }

            &.one-half {
                width: 49% !important;
            }

            &.two-thirds {
                width: 66% !important;
            }

            &.three-quarters {
                width: 74.5% !important;
            }

            .flexbox-grid {
                &.two-column {
                    .grid-item {
                        width: 49%;
                    }
                }
        
                &.three-column {
                    .grid-item {
                        width: 32%;
                    }
                }
        
                &.four-column {
                    .grid-item {
                        width: 23.5%;
                    }
                }
            }
        }
    
        &.two-column {
            .grid-item {
                width: 49%;
            }
        }
    
        &.three-column {
            .grid-item {
                width: 32%;
            }
        }

        &.four-column {
            .grid-item {
                width: 23.5%;
            }
        }

        &.five-column {
            .grid-item {
                width: 18.4%;
            }
        }
    
        &.six-column {
            .grid-item {
                width: 15%;
            }
        }

        &.seven-column {
            .grid-item {
                width: 12.57%;
            }
        }
    
        &.eight-column {
            .grid-item {
                width: 10.75%;
            }
        }
    
        &.nine-column {
            .grid-item {
                width: 9.33%;
            }
        }

        &.ten-column {
            .grid-item {
                width: 8.2%;
            }
        }

        &.eleven-column {
            .grid-item {
                width: 7.27%;
            }
        }

        &.twelve-column {
            .grid-item {
                width: 6.5%;
            }
        }
    }
}

