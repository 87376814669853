//mw faqs
.faqs-container,
.popular-questions-container {
    border: 1px solid $alto;
    border-radius: 4px;
    display: none;
    padding: 30px;

    &.open {
        display: block;
    }

    h1 {
        background-color: $alto;
        color: $white;
        padding: 5px 10px;

        &:after {
            display: none;
        }
    }

    h2 {
        color: $biscay;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    p {
        margin: 0 0 10px 0;
    }

    a {
        color: $biscay;
        text-decoration: underline;
    }

    .toggle-button {
        border-bottom: 1px solid $alto;
        color: $emperor;
        height: auto;
        padding: 15px 0;
        width: 100%;

        label {
            cursor: pointer;
            font-size: 14px;
            font-weight: normal;
        }

        .u-icon-accordion:before {
            color: $silver3;
            top: 17px;
            right: 0;
        }
    }

    .toggle-content {
        padding: 30px 0 30px 30px;
    }
}

.popular-questions-container {
    background: $mercury;
    border: none;
    display: block;
    margin-bottom: 20px;
}