

.notification-count {
    background: $red-orange;
    border-radius: 100%;
    color: $white;
    display: block;
    font-family: $base-font-family;
    font-size: 13px;
    font-weight: bold;
    height: 20px;
    text-align: center;
    top: -3px;
    right: -7px;
    position: absolute;
    width: 20px;
    z-index: 3;
}

.form-item {
    .notification-count {
        top: -5px;
    }
}

@include bp(sm) {
    .form-item {
        .notification-count {
            top: 17px;
        }
    }
}

//svg-list
ul.svg-list {
    li {
        .notification-count {
            top: 10px;
            right: inherit;
            left: 68%;
        }
    }

    &.view-list {
        li {
            .notification-count {
                top: -3px;
                right: inherit;
                left: 35px;
            }
        }
    }
}

@include bp(lg) {
    ul.svg-list {
        li {
            .notification-count {
                left: 63%;
            }
        }
    }
}

@include bp(xlg) {
    ul.svg-list {
        li {
            .notification-count {
                left: 60%;
            }
        }
    }
}


//utility-nav
section.utility-nav {
    .notifications {
        .notification-count {
            background: $marigold;
            font-size: 11px;
            height: 15px;
            padding-top: 2px;
            width: 15px;
        }
    }
}

.new-header.header-quality-control,
.new-header.header-marketwatch {
    .header-utility-nav-container {
        section {
            &.alerts {    
                &.new-notifications {
                    a {
                        .notification-count {
                            background: $rio-grande;
                            border-radius: 100%;
                            color: $mine-shaft;
                            display: block;
                            font-family: $base-font-family;
                            font-size: 10px;
                            font-weight: 600;
                            height: 15px;
                            text-align: center;
                            top: 28px;
                            right: 0;
                            padding-top: 4px;
                            position: absolute;
                            width: 15px;
                            z-index: 3;                    
                        }
                    }
                }
            }
        }
    }
}

@include bp(sm) {
    .header-utility-nav-container {
        section {
            &.alerts {    
                &.new-notifications {
                    a {
                        .notification-count {
                            font-size: 12px;
                            height: 20px;
                            top: 26px;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }
}