$spacing-steps: 20;

//universal min-height
@for $index from 1 to $spacing-steps {
    .u-min-height--#{$index} {
        min-height: bsu($index / 2) !important;
    }
}

//universal min-width
@for $mwidth from 1 to 5000 {
    .u-min-width--#{$mwidth} {
        min-width: #{$mwidth}#{'px'} !important; 
    }
}

.u-w-100 {
    width: 100% !important;
}

.u-w-auto {
    width: auto !important;
}

//pixel based widths
@mixin pixelwidths-universal-x {
    @for $i from 1 through 1000 {
        .u-pixels-#{$i} { 
            width: #{$i}#{'px'} !important; 
        }
    }
}

@include pixelwidths-universal-x;
