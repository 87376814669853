.u-bg--center {
    background-repeat: no-repeat !important;
    background-position: center center !important; 
}

.u-bg--top {
    background-repeat: no-repeat !important; 
    background-position: top center !important;    
}

.u-bg--right {
    background-repeat: no-repeat !important; 
    background-position: center right !important;  
}

.u-bg--bottom {
    background-repeat: no-repeat !important; 
    background-position: bottom center !important; 
}

.u-bg--left {
    background-repeat: no-repeat !important; 
    background-position: center left !important;   
}

@each $key, $breakpoint in $breakpoints {

    .#{key}-u-bg--center {
        @include bp($breakpoint) {
            background-repeat: no-repeat !important;
            background-position: center center !important; 
        }
    }
    
    .#{key}-u-bg--top {
        @include bp($breakpoint) {
            background-repeat: no-repeat !important; 
            background-position: top center !important;    
        }
    }
    
    .#{key}-u-bg--right {
        @include bp($breakpoint) {
            background-repeat: no-repeat !important; 
            background-position: center right !important;  
        }
    }
    
    .#{key}-u-bg--bottom {
        @include bp($breakpoint) {
            background-repeat: no-repeat !important; 
            background-position: bottom center !important; 
        }
    }
    
    .#{key}-u-bg--left {
        @include bp($breakpoint) {
            background-repeat: no-repeat !important; 
            background-position: center left !important;   
        }
    }

}