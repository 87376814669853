.u-menu-link{
    .k-icon{
      color: $almost-black;
      opacity: 1;
    }
}

.u-menu{
  position: absolute;
  z-index: 3;
  width: 145px;
  ul {
      background: $white;
      border-radius: 6px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
      list-style: none;
      margin: 0;
      padding: 7px;
      
      li {
          background: transparent;
          font-size: 16px;
          margin: 0;
          a {
              color: $mine-shaft;
              display: block;
              font-weight: normal;
              text-decoration: none;
              width: 100%;
              padding-top: 3px;
              padding-left: 5px;

              &:hover {
                  color: $marigold-hover;
              }
          }
          &:last-child{
            a { 
              padding-bottom: 3px;
            }
          }
      }
    }
  }
