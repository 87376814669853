.drag-drop-container {
    background: $concrete;
    margin: 0 bsu(0.25);
    min-height: 38px;
}

ul.sortable {
    list-style: none;
    margin: 0 bsu(0.25);

    li.draggable {
        background: $concrete;
        border-bottom: 1px solid $white;
        box-shadow: inset 0 0 20px rgba($gray-chateau, 0.5);
        cursor: pointer;
        font-size: 16px;
        margin: 0;
        padding: 7px 7px 7px 45px;
        position: relative;

        &:before {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            content: '\e8fe';
            font-size: 22px;
            left: 13px;
            position: absolute;
            top: 7px;
        }

        &:hover {
            box-shadow: inset 0 0 20px rgba($gray-chateau, 0.9);

            &:after {
                color: $white;
            }
        }

        &.ui-draggable-dragging {
            background: $pistachio;
            box-shadow: inset 0 0 20px rgba($pistachio, 0.9);
            color: $white;
        }
    }
}