.u-bg--cover {
    background-size: cover!important;
}

.u-bg--contain {
    background-size: contain!important;
}

@each $key, $breakpoint in $breakpoints {

    .#{key}-u-bg--cover {
        @include bp($breakpoint) {
            background-size: cover!important;
        }
    }
    
    .#{key}-u-bg--contain {
        @include bp($breakpoint) {
            background-size: contain!important;
        }
    }

}