.ui-helper-reset {
    line-height: 1.5;
}

.ui-accordion {
    .group {
        position: relative;
    }

    .resource-actions {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;

        a {
            padding: 7px bsu(0.25) 7px bsu(0.25);
        }

        [class^="u-icon-"],
        [class*=" u-icon-"] {
            &:before {
                color: $white;
                opacity: 0.8;
                top: 7px;
            }

            &:hover {
                &:before {
                    color: $white;
                    opacity: 1;
                }
            }
        }
    }

    h3.ui-accordion-header,
    h3.ui-accordion-header.ui-state-default,
    h3.ui-accordion-header.ui-accordion-header-active,
    h3.ui-accordion-header.ui-state-active {
        background: $hub;
        border-radius: 0;
        color: $white;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        margin: 0 0 bsu(0.5) 0;
        outline: none;
        padding: bsu(0.25) 0 bsu(0.3) 10%;
        position: relative;
        z-index: 2;

        &:before {
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
    
            content: "\edf5";
            font-size: 16px;
            left: 10px;
            position: absolute;
            top: 8px;
            transition: all $base-duration $base-timing;
            z-index: 2;
        }

        &.bg-orange,
        &.bg-marigold {
            background: $marigold;
        }

        &.bg-pistachio {
            background: $pistachio;
        }

        &.bg-hub-light {
            background: $hub-light;
        }

        &.bg-hub-lightest {
            background: $hub-lightest;
            color: $ship-gray;
        }

        &.bg-iron {
            background: $iron;
            color: $ship-gray;
        }

        a {
            position: absolute;
            right: 10px;
            top: 8px;
            z-index: 20;
        }
    }

    h3.ui-accordion-header.ui-accordion-header-active,
    h3.ui-accordion-header.ui-state-active {
        &:before {
            transform: rotate(90deg);
        }
    }

    .ui-accordion-header-icon.ui-icon.ui-icon-triangle-1-e,
    .ui-accordion-header-icon.ui-icon.ui-icon-triangle-1-s {
        display: none;
    }

    .ui-accordion-content {
        padding: 0;
    }

    .ui-widget-content {
        background: transparent;
        border-radius: 0;
    }

    .toggle-content {
        position: relative;
    }
}

@include bp(sm) {
    .ui-accordion {
        h3.ui-accordion-header,
        h3.ui-accordion-header.ui-state-default,
        h3.ui-accordion-header.ui-accordion-header-active,
        h3.ui-accordion-header.ui-state-active {
            padding-left: 5%;
        }
    }
}

@include bp(md) {
    .ui-accordion {
        h3.ui-accordion-header,
        h3.ui-accordion-header.ui-state-default,
        h3.ui-accordion-header.ui-accordion-header-active,
        h3.ui-accordion-header.ui-state-active {
            padding-left: 4%;
        }
    }
}

@include bp(lg) {
    .ui-accordion {
        h3.ui-accordion-header,
        h3.ui-accordion-header.ui-state-default,
        h3.ui-accordion-header.ui-accordion-header-active,
        h3.ui-accordion-header.ui-state-active {
            padding-left: 8%;

            &:before {
                left: 50px;
            }

            &:after {
                font-family: 'icomoon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
        
                color: $white;
                content: '\e8fe';
                font-size: 22px;
                left: 10px;
                position: absolute;
                top: 4px;
                transition: all $base-duration $base-timing;
                z-index: 2;
            }
        }

        &.non-sortable {
            h3.ui-accordion-header,
            h3.ui-accordion-header.ui-state-default,
            h3.ui-accordion-header.ui-accordion-header-active,
            h3.ui-accordion-header.ui-state-active {
                padding-left: bsu(2.5);

                &:before {
                    left: 10px;
                }

                &:after {
                    display: none;
                }
            }
        }
    }
}

//new design
body.new-design {
    
}

