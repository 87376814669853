//quickview-widgets
.quickview-widgets {
    width: 100%;
}

.quickview-widget {
    margin: 0 0 10px 0;

    &:last-of-type {
        margin: 0 0 20px 0;
    }
    
    .toggle-button {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;

        background: $pistachio;
        height: 35px;
        width: 100%;

        span.u-icon-accordion {
            background: $marigold;
            color: $white;
            display: block;
            height: 35px;
            width: 35px;

            &:before {
                color: $white;
                font-size: 20px;
                font-weight: bold;
                left: 8px;
                top: 6px;
            }

            &:hover,
            &:active,
            &:visited {
                &:before {
                    color: $white;;
                }
            }
        }

        h1 {
            color: $white;
            font-size: 18px;
            font-weight: 700;
            margin: 0;
            padding: 0 10px;

            &:after {
                display: none;
            }
        }

        label {
            margin: 0;
        }
    }

    .toggle-content {
        background: $ada-grey-5;
        padding: 10px;

        header {
            background: $grey;
            color: $white;
            font-size: 16px;
            font-weight: 700;
            padding: 3px 10px;
            width: 100%;
        }

        p {
            margin: 0 5% 0 0;
            width: 74%;
        }

        .action-buttons {
            width: auto;

            a {
                display: block;
                float: left;
            }
        }

        &.logs-widget {
            section {
                align-items: flex-start;
            }
        }

        section {
            display: flex;
            flex-direction: row;
            flex-flow: row wrap;
            align-content: flex-start;
            align-items: center;
            justify-content: space-between;

            margin: 0 0 10px 0;
            width: 100%;

            section {
                background: $white;
                margin: 0;
                padding: 5px 10px;

                &:nth-of-type(2n+2) {
                    background: $ada-grey-5;
                }
            }

            &.button-container {
                background: transparent;
                padding: 0;

                .btn-pistachio,
                .new-button {
                    margin: 10px 0 0 0;
                }

                .btn {
                    width: 49%;
                }

                .btn-pistachio {
                    padding: 7px 7px 8px 7px;
                }
            }
        }

        .btn-pistachio,
        .new-button {
            font-size: 14px;
            font-weight: 500;
            padding: 7px 15px 8px 15px;
        }

        &.weather-widget {
            section {
                padding: 0;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            width: 100%;

            li {
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: flex-start;
                justify-content: space-between;

                background: $white;
                margin: 0;
                padding: 5px 10px;

                &:nth-of-type(2n+2) {
                    background: $ada-grey-5;
                }

                label {
                    color: $ship-gray;
                    font-weight: 600;
                    text-align: left;
                    word-break: break-all;
                }

                p {
                    font-size: 15px;
                    font-weight: 600;
                    margin: 0;
                    text-align: right;
                    width: auto;

                    span {
                        font-size: 9px;
                    }
                }
            }
        }
    }
}

@include bp(md) {
    .quickview-widgets {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
    }

    .quickview-widget {
        margin-right: 2%;
        width: 32%;

        &:last-of-type {
            margin-right: 0;
        }

        .toggle-button {
            border-radius: 6px 6px 0 0;
            pointer-events: none;

            span.u-icon-accordion {
                display: none;
            }
        }

        .toggle-content {
            display: block;

            .fixed-height {
                height: 216px;
                overflow-y: scroll;
                padding: 0;

                section {
                    width: 100%;
                }
            }

            &.logs-widget {
                section {
                    padding: 5px 10px 8px 10px;

                    &.fixed-height {
                        padding: 0;
                    }
                }
            }

            p {
                width: 70%;
            }
        }
    }
}

@include bp(lg) {
    .quickview-widget {
        .toggle-content {
            p {
                width: 64%;
            }
        }
    }
}

@include bp(lgg) {
    .quickview-widget {
        .toggle-content {
            p {
                width: 70%;
            }
        }
    }
}

//dashboard nav
.dashboard-nav {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
}

.dashboard-nav-section {
    display: none;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    margin: 0 2% 10px 0;
    width: 49%;

    &:nth-of-type(2n+2) {
        margin: 0 0 10px 0;
    }

    &:last-of-type {
        margin: 0;
    }

    header {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: center;
        justify-content: flex-start;

        background: $pistachio;
        border-radius: 6px 6px 0 0;
        width: 100%;

        .svg-container {
            display: block;
            height: 40px;
            padding-top: 5px;
            text-align: center;
            width: 40px;
        }

        svg {
            fill: $white;
            height: auto;
            max-height: 30px;
            max-width: 30px;
            width: auto;

            &.farm-details {
                max-height: 25px;
                max-width: 25px;
                margin-top: 2px;
            }
        }

        h1 {
            color: $white;
            font-size: 18px;
            font-weight: 700;
            margin: 0;
            padding: 5px 10px;

            &:after {
                display: none;
            }
        }
    }

    .content-container {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;

        background: $ada-grey-5;
        height: 250px;
        padding: 10px 10px 0 10px;
        width: 100%;

        section {
            margin: 0 4% 10px 0;
            width: 48%;

            &:nth-of-type(2n+2) {
                margin: 0 0 10px 0;
            }

            a {
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: center;
                justify-content: flex-start;

                h2 {
                    color: $ship-gray;
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0;
                }

                .svg-container {
                    display: block;
                    height: 30px;
                    margin: 0 10px 0 0;
                    text-align: center;
                    width: 30px;
                }

                svg {
                    fill: $ship-gray !important;
                    height: auto;
                    max-height: 30px;
                    max-width: 30px;
                    width: auto;
                }

                &:hover {
                    h2 {
                        color: $ship-gray;
                    }

                    svg {
                        fill: $ship-gray;
                    }
                }
            }
        }
    }

    &.weather-stations {
        display: block;
        width: 100%;
        margin: 0 0 20px 0;

        .content-container {
            height: auto;
            padding: 10px;

            header {
                background: $marigold;
                box-shadow: none;
                color: $white;
                font-size: 16px;
                font-weight: 500;
                padding: 2px 10px;
                width: 100%;
            }
    
            section {
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: center;
                justify-content: space-between;
    
                background: $off-white;
                margin: 0;
                padding: 0;
                width: 100%;
    
                &:nth-of-type(2n+2) {
                    background: $off-white-dark;
                }
    
                &.button-container {
                    background: transparent;
                    padding: 0;
    
                    .btn {
                        width: 49%;
                    }
    
                    .btn-pistachio {
                        padding: 7px 7px 8px 7px;
                    }
    
                    .new-button {
                        padding: 7px 7px 8px 35px;
                    }
                }
            }
    
            .btn-pistachio,
            .new-button {
                font-size: 14px;
                font-weight: 500;
                margin: 10px 0 0 0;
                padding: 7px 15px 8px 15px;
            }
    
            ul {
                display: flex;
                flex-direction: row;
                flex-flow: row wrap;
                align-items: flex-start;
                justify-content: flex-start;
    
                list-style: none;
                margin: 0;
                padding: 10px;
                width: 100%;
    
                li {
                    display: flex;
                    flex-direction: row;
                    flex-flow: row wrap;
                    align-items: flex-start;
                    justify-content: flex-start;
                    
                    margin: 0 4% 0 0;
                    width: 22%;
    
                    &:last-of-type {
                        margin: 0;
                    }
    
                    svg {
                        fill: $marigold !important;
                        height: auto;
                        margin: 0 5px 0 0;
                        width: 20px;
                    }
    
                    p {
                        font-size: 15px;
                        font-weight: 600;
                        margin: 0;
                        width: auto;
    
                        span {
                            font-size: 9px;
                        }
                    }
                }
            }
        }
    }
}

.dashboard-nav-btns {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;

    a.btn.icon-btn {
        background: $hub;
        margin-bottom: 10px;
        width: 48.5%;

        &:hover {
            background: $pistachio;
        }

        &:last-of-type,
        &:nth-last-of-type(2) {
            margin-bottom: 0;
        }

        &.not-active {
            background: $ada-grey-3;
            box-shadow: none;
            color: $white;
            pointer-events: none;

            .svg-container {
                background: $ada-grey-3;
                box-shadow: inset 0 0 50px rgba($almost-black,0.3);

                svg {
                    fill: $concrete;
                }
            }
        }
    }
}

@include bp(md) {
    .dashboard-nav-section {
        display: flex;

        .content-container {
            height: 322px;

            section {
                a {
                    .svg-container {
                        height: 25px;
                        margin: 0 5px 0 0;
                        width: 25px;
                    }
                }
            }
        }

        &.weather-stations {
            margin: 0 2% 10px 0;
            width: 49%;

            .content-container {
                height: 250px;

                ul {
                    padding: 5px 10px;
                }
            }
        }
    }

    .dashboard-nav-btns {
        margin: 0;
        width: 49%;
    }
}

@include bp(lgg) {
    .dashboard-nav-section {
        margin: 0 1% 0 0;
        width: 24.25%;

        &:nth-of-type(2n+2) {
            margin: 0 1% 0 0;
        }

        .content-container {
            height: 322px;

            section {
                a {
                    h2 {
                        font-size: 13px;
                        width: 70%;
                    }
                }
            }
        }

        &.weather-stations {
            margin: 0 1% 0 0;
            width: 24.25%;

            .content-container {
                height: 260px;

                ul {
                    padding: 8px 10px;
                }
            }
        }
    }

    .dashboard-nav-btns {
        margin: 0;
        width: 24.25%;
    }
}

@include bp(xlg) {
    .dashboard-nav-section {
        .content-container {
            section {
                a {
                    .svg-container {
                        height: 30px;
                        margin: 0 10px 0 0;
                        width: 30px;
                    }

                    h2 {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

//new design
body.new-design {
    .dashboard-nav {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;

        list-style: none;
        margin: 0;

        li {
            background: $killarney;
            border-radius: 6px;
            color: $white;
            margin: 0 0 10px 0;
            padding: 30px;
            width: 100%;

            &:nth-of-type(2) {
                background: $spring-leaves;
            }

            &:nth-of-type(3) {
                background: $plantation;
            }

            &:nth-of-type(4) {
                background: $killarney;
            }

            h1,
            h2,
            h3, 
            p {
                color: $white;
            }

            .btn.btn-ghost {
                border: 2px solid $white;
                color: $white;
                margin: 0;
                min-width: 150px;

                &:hover,
                &:focus {
                    background: $white;
                    color: $emperor;
                }
            }
        }
    }
}

@include bp(sm) {
    body.new-design {
        .dashboard-nav {
            li {
                width: 49%;
            }
        }
    }
}

@include bp(lg) {
    body.new-design {
        .dashboard-nav {
            li {
                padding: 50px;
                width: 49.5%;
            }
        }
    }
}
