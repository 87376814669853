.three-columns {
    border-bottom: 2px solid $hub;
    margin-bottom: bsu(2);

    h1 {
        width: 100%;
    }

    section {
        background: $concrete;
        margin-bottom: bsu(1);
        padding: bsu(1);

        p {
            margin: 0;
        }
    }
}

@include bp(md) {
    .three-columns {
        display: flex;
        align-items: left;
        justify-content: left;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;
    
        align-content: flex-end;

        section {
            margin: 0 2% bsu(1) 0;
            width: 32%;

            &:nth-of-type(3n+3) {
                margin: 0 0 bsu(1) 0;
            }
        }
    }
}

