@for $index from 1 to 10 {

    .u-letter-spacing-#{index} {
        letter-spacing: #{index}px !important;
    }

    @each $key, $breakpoint in $breakpoints {
        .#{key}-u-letter-spacing-#{index} {
            @include bp($breakpoint) {
                letter-spacing: #{index}px !important;
            }
        }
    }

}