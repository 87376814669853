// Text Aligment Helpers
// ------------------------------------------------------------------------------------

.u-text-left,
.u-text--left,
.u-text-align--left {
    text-align: left !important;
}

.u-text-center,
.u-text--center,
.u-text-align--center {
    text-align: center !important;
}

.u-text-right,
.u-text--right,
.u-text-align--right {
    text-align: right !important;
}

@each $key, $breakpoint in $breakpoints {
    
    .#{$key}-u-text-left,
    .#{$key}-u-text--left,
    .#{$key}-u-text-align--left {
        @include bp($breakpoint) {
            text-align: left !important;
        }
    }

    .#{$key}-u-text-center,
    .#{$key}-u-text--center,
    .#{$key}-u-text-align--center {
        @include bp($breakpoint) {
            text-align: center !important;
        }
    }

    .#{$key}-u-text-right,
    .#{$key}-u-text--right,
    .#{$key}-u-text-align--right {
        @include bp($breakpoint) {
            text-align: right !important;
        }
    }

}